import { compressImage } from '~/lib/utils'
import { switchAutoMakeMode } from '~/redux/autoMaskReducer'
import { requestUploadNewFile } from '~/redux/fileUploaderReducer'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Cookies from 'js-cookie'
import Loader from '../loader'
import { advertismentModals } from '../modals/adsModals'
import OneButtonModal from '../modals/modal_oneButton'

const Canvas = ({
	genReqPending,
	setShowTip,
	progress,
	resultReady,
	hideCanvas,
	brushSize,
	tempImage,
	maskImage,
	setMaskImage,
	originalImage,
	setOriginalImage,
	setUndo,
	isUndo,
	errorMessage,
	generationObjectAge,
	setIsWariningVisible,
	currentTool,
	setCurrentTool,
	userInfo,
	isLoaded,
	isNewFileRequested,
	isDemo,
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const isUploadFileRequested = useSelector(
		(state) => state.fileUploaderReducer.value
	)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const autoMask = useSelector((state) => state.autoMaskReducer.value)
	if (autoMask === 'auto') {
		tempImage = originalImage
	}

	const onDrop = useCallback(
		(acceptedFiles) => {
			const file = acceptedFiles[0]
			sessionStorage.setItem('userWarnedAboutForbiddenContent', 'false')
			setIsLoading(true)
			compressImage(file)
				.then((x) => {
					setOriginalImage(x)
					localStorage.setItem('originalImage', x)
					const ignoreHelp = Cookies.get('ignorehelp') === 'true'
					if (setShowTip) setShowTip(!ignoreHelp)
					setIsLoading(false)
				})
				.catch((e) => {
					console.error(e)
					setIsLoading(false)
				})
		},
		[compressImage]
	)
	useEffect(() => {
		const originalImageLocal = localStorage.getItem('originalImage')
		if (originalImageLocal) {
			setOriginalImage(originalImageLocal)
			localStorage.setItem('restore', false)
		}
	}, [])

	const [currentIndex, setCurrentIndex] = useState(0)

	const adsModals = advertismentModals({ userInfo })

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentIndex((currentIndex + 1) % adsModals.length)
		}, 15000)

		return () => clearInterval(intervalId)
	}, [currentIndex])

	function showImage() {
		if (errorMessage === 'CHILD BLOCK') {
			setIsWariningVisible(true)
			return
		}

		if (errorMessage) {
			return (
				<div className='h-auto text-center text-[16px]'>{errorMessage}</div>
			)
		}
		return (
			<>
				<img
					src={tempImage ? tempImage : maskImage || originalImage}
					className='inset-0'
					alt='Could not process the image. Please try another'
					style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
				/>
				{!resultReady && (
					<div className='absolute inset-0 flex h-full w-full items-center justify-center'>
						<SwitchTransition>
							<CSSTransition key={currentIndex} classNames='fade'>
								<div className='flex w-full transition-all duration-500'>
									{adsModals[currentIndex]}
								</div>
							</CSSTransition>
						</SwitchTransition>
					</div>
				)}
			</>
		)
	}

	const dropzoneObject = useDropzone({
		onDrop,
		accept: {
			'image/jpeg': [],
			'image/png': [],
			'image/heic': [],
		},
		maxSize: 15 * 1048576,
		multiple: false,
	})

	useEffect(() => {
		if (dropzoneObject?.open && isUploadFileRequested) {
			setOriginalImage(null)
			dropzoneObject.open()
			dispatch(requestUploadNewFile(false))
		}
	}, [isUploadFileRequested])

	const { getRootProps, getInputProps, isDragActive } = dropzoneObject
	const modesHelpLearned = true

	useEffect(() => {
		dispatch(switchAutoMakeMode('auto'))
	}, [])

	return (
		<div
			className={
				autoMask === 'auto'
					? 'edit-inputimg max-h-[80vh] mmd:max-h-[100vh]'
					: 'edit-inputimg relative max-h-[80vh] mmd:max-h-[100vh]'
			}
			{...(originalImage ? {} : getRootProps())}
		>
			{isLoaded &&
				userInfo.generationsLeft <= 0 &&
				originalImage !== undefined &&
				originalImage !== null &&
				!resultReady && (
					<div className='pointer-events-none fixed left-0 top-0 z-[100] flex h-full w-full flex-col bg-[#202020]/90 backdrop-blur-[32px] animate-in fade-in-5 duration-500'>
						<OneButtonModal
							header={'Your Free Generations Have Run Out'}
							description={'Subscribe and unlock premium features!'}
							buttonText={'Explore Plans'}
							buttonAction={() => navigate('/plan')}
							isClosable={false}
						/>
					</div>
				)}

			{!originalImage && !isNewFileRequested && (
				<>
					<input {...getInputProps()} />
					{
						<button
							disabled={isLoading}
							className='upload-canvas justify-center'
						>
							{isLoading ? (
								<div className='flex flex-col items-center justify-center gap-6'>
									<svg
										width='52'
										height='52'
										viewBox='0 0 15 15'
										xmlns='http://www.w3.org/2000/svg'
										className='animate-spin justify-center'
									>
										<path
											d='M7.56006 13.8801C7.28006 13.8801 7.06006 13.6601 7.06006 13.3801C7.06006 13.1001 7.28006 12.8801 7.56006 12.8801C10.5201 12.8801 12.9401 10.4701 12.9401 7.50012C12.9401 4.53012 10.5301 2.12012 7.56006 2.12012C7.28006 2.12012 7.06006 1.90012 7.06006 1.62012C7.06006 1.34012 7.28006 1.12012 7.56006 1.12012C11.0801 1.12012 13.9401 3.98012 13.9401 7.50012C13.9401 11.0201 11.0801 13.8801 7.56006 13.8801Z'
											fill='#fff'
										/>
									</svg>
									<div className='edit-text06 flex max-w-[170px] flex-col mmd:max-w-none'>
										<p>Image is loading..</p>
									</div>
								</div>
							) : (
								<div className='edit-text06 flex flex-col'>
									<p>Upload a photo</p>
									<p>.jpg .png .heic</p>
								</div>
							)}
						</button>
					}
				</>
			)}
			{originalImage && !hideCanvas && modesHelpLearned && (
				<OriginalImageArea originalImage={originalImage} />
			)}
			{originalImage && !hideCanvas && modesHelpLearned && (
				<DrawingArea
					genReqPending={genReqPending}
					brushSize={brushSize}
					isUndo={isUndo}
					setUndo={setUndo}
					tempImage={tempImage}
					originalImage={originalImage}
					maskImage={maskImage}
					setMaskImage={setMaskImage}
					currentTool={currentTool}
				/>
			)}
			{hideCanvas && maskImage !== undefined && maskImage !== null && (
				<>
					{!resultReady && progress > 0 && <Loader />}
					{showImage()}
				</>
			)}
		</div>
	)
}
const OriginalImageArea = ({ originalImage }) => {
	const canvasRef = useRef(null)
	const [context, setContext] = useState(null)

	useEffect(() => {
		const canvas = canvasRef.current
		const ctx = canvas.getContext('2d')
		setContext(ctx)
	}, [])

	useEffect(() => {
		if (originalImage === null) setCanvasStates([])

		if (originalImage && context) {
			const loadImage = async () => {
				window.dataLayer = window.dataLayer || []
				window.dataLayer.push({
					event: 'aevent',
					event_name: 'file_uploaded',
				})

				if (window.umami) {
					window.umami.track('File uploaded')
				}

				const image = new Image()
				var loadImg2 = new Promise((resolve) => {
					image.onload = resolve
					image.src = originalImage
				})
				await loadImg2

				const pixelRatio = window.devicePixelRatio || 1

				canvasRef.current.width = image.width * pixelRatio
				canvasRef.current.height = image.height * pixelRatio

				// Scale the context to match the device pixel ratio
				context.scale(pixelRatio, pixelRatio)

				// Clear the canvas before drawing the image
				context.clearRect(0, 0, image.width, image.height)

				context.drawImage(image, 0, 0, image.width, image.height)

				// Scale the context back to match the original size
				context.scale(1 / pixelRatio, 1 / pixelRatio)
				context.setTransform(1, 0, 0, 1, 0, 0)
			}

			loadImage()
		}
	}, [originalImage, context])

	return (
		<div className='box-canvas inset-0 max-h-[80vh] mmd:max-h-[100vh]'>
			<canvas
				ref={canvasRef}
				className='max-h-[80vh] mmd:max-h-[100vh]'
				style={{
					touchAction: 'none',
				}}
			/>
		</div>
	)
}

const DrawingArea = ({
	genReqPending,
	brushSize,
	tempImage,
	originalImage,
	maskImage,
	setMaskImage,
	setUndo,
	isUndo,
	currentTool,
}) => {
	const [canvasStates, setCanvasStates] = useState([])
	const canvasRef = useRef(null)
	const [context, setContext] = useState(null)
	const [drawing, setDrawing] = useState(false)
	const [rafId, setRafId] = useState(null)
	const [prevX, setPrevX] = useState(null)
	const [prevY, setPrevY] = useState(null)
	const [isMaskLoaded, setMaskLoaded] = useState(false)

	const autoMask = useSelector((state) => state.autoMaskReducer.value)
	const dispatch = useDispatch()

	useEffect(() => {
		const canvas = canvasRef.current
		const ctx = canvas.getContext('2d')
		setContext(ctx)
	}, [])

	useEffect(() => {
		if (originalImage === null) setCanvasStates([])

		if ((originalImage || tempImage) && context) {
			const loadImage = async () => {
				const image = new Image()
				image.crossOrigin = 'Anonymous'
				var loadImg2 = new Promise((resolve) => {
					image.onload = resolve
					image.src = tempImage ? tempImage : originalImage
				})
				await loadImg2

				const pixelRatio = window.devicePixelRatio || 1

				canvasRef.current.width = image.width * pixelRatio
				canvasRef.current.height = image.height * pixelRatio

				// Scale the context to match the device pixel ratio
				context.scale(pixelRatio, pixelRatio)

				// Clear the canvas before drawing the image
				context.clearRect(0, 0, image.width, image.height)

				// Save the initial canvas state after the image is drawn
				const canvasImageData = context.getImageData(
					0,
					0,
					canvasRef.current.width,
					canvasRef.current.height
				)
				let maskDataURL = localStorage.getItem('maskSavedImage')
				if (maskDataURL) {
					const maskImage = new Image()
					maskImage.src = maskDataURL
					maskImage.onload = () => {
						context.drawImage(maskImage, 0, 0, image.width, image.height)
						setMaskLoaded(true)

						setCanvasStates([canvasImageData])

						// Scale the context back to match the original size
						context.scale(1 / pixelRatio, 1 / pixelRatio)
						context.setTransform(1, 0, 0, 1, 0, 0)
					}
				} else {
					setCanvasStates([canvasImageData])

					// Scale the context back to match the original size
					context.scale(1 / pixelRatio, 1 / pixelRatio)
					context.setTransform(1, 0, 0, 1, 0, 0)
				}
			}

			loadImage()
		}
	}, [tempImage, originalImage, context])

	const drawWithRAF = useCallback(
		(event) => {
			if (!drawing || maskImage || autoMask === 'auto') return
			event.preventDefault()

			const { x, y } = getCanvasCoordinates(event)

			const drawLineSegment = () => {
				context.lineWidth = (canvasRef.current.width * brushSize) / 100

				context.beginPath()
				context.moveTo(prevX, prevY)
				context.lineTo(x, y)
				context.stroke()

				setPrevX(x)
				setPrevY(y)
			}

			requestAnimationFrame(drawLineSegment)
		},
		[drawing, prevX, prevY, context, autoMask]
	)

	const getCanvasCoordinates = useCallback(
		(event) => {
			const clientX = event.touches ? event.touches[0].clientX : event.clientX
			const clientY = event.touches ? event.touches[0].clientY : event.clientY
			const rect = canvasRef.current.getBoundingClientRect()
			const scaleX = canvasRef.current.width / rect.width
			const scaleY = canvasRef.current.height / rect.height
			return {
				x: (clientX - rect.left) * scaleX,
				y: (clientY - rect.top) * scaleY,
			}
		},
		[canvasRef]
	)
	const startDrawing = useCallback(
		(event) => {
			if (!maskImage) {
				event.preventDefault()

				const canvasImageData = context.getImageData(
					0,
					0,
					canvasRef.current.width,
					canvasRef.current.height
				)
				setCanvasStates([...canvasStates, canvasImageData])

				const { x, y } = getCanvasCoordinates(event)

				context.lineWidth = (canvasRef.current.width * brushSize) / 100
				context.lineCap = 'round'

				if (currentTool == 'brush') {
					context.globalCompositeOperation = 'source-over'
					context.strokeStyle = '#eb7100'
				} else if (currentTool == 'rubber') {
					context.globalCompositeOperation = 'destination-out'
					context.strokeStyle = 'rgba(0,0,0,1)'
				}
				context.beginPath()
				context.moveTo(x, y)

				setPrevX(x)
				setPrevY(y)

				setDrawing(true)
			}
		},
		[canvasStates, context, getCanvasCoordinates, currentTool]
	)

	const stopDrawing = useCallback(() => {
		if (rafId) {
			cancelAnimationFrame(rafId)
			setRafId(null)
		}
		setDrawing(false)
		saveMask()
	}, [rafId])

	useEffect(() => {
		if (isUndo) {
			undo()
		}
	}, [isUndo])

	const saveMask = useCallback(() => {
		const canvas = canvasRef.current
		const ctx = canvas.getContext('2d')
		const canvasImageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
		const maskUrl = imageDataToDataURL(canvasImageData)
		localStorage.setItem('maskSavedImage', maskUrl)
	}, [drawing])

	const undo = useCallback(() => {
		if (canvasStates.length <= 1) {
			setUndo(false)
			return
		}

		const newCanvasStates = [...canvasStates]
		const lastCanvasState = newCanvasStates.pop()
		setCanvasStates(newCanvasStates)

		context.putImageData(lastCanvasState, 0, 0)
		setUndo(false)
	}, [canvasStates, context, setUndo])

	const applyMask = useCallback(async () => {
		const canvas = canvasRef.current
		const ctx = canvas.getContext('2d')
		const canvasImageData = ctx.getImageData(0, 0, canvas.width, canvas.height)

		if (autoMask !== 'auto') {
			const max = canvas.width * canvas.height * 4
			let maskPixelsCount = 0
			for (let i = 0; i < max; i += 4) {
				if (
					canvasImageData.data[i] == 235 &&
					canvasImageData.data[i + 1] == 113 &&
					canvasImageData.data[i + 2] == 0
				) {
					maskPixelsCount++
				}
			}

			if (maskPixelsCount < 100) {
				dispatch(switchAutoMakeMode('auto'))

				// setMaskImage(null)
				// stopDrawing()
				// return
			}
		}

		const maskUrl = imageDataToDataURL(canvasImageData)
		setMaskImage(
			await uniteCanveses(maskUrl, tempImage ? tempImage : originalImage)
		)
		stopDrawing()
	}, [canvasRef, setMaskImage, autoMask])

	useEffect(() => {
		if (maskImage !== undefined) return
		if (maskImage === undefined) {
			applyMask()
		}
	}, [maskImage, applyMask])

	useEffect(() => {
		if (context) {
			canvasRef.current.addEventListener('touchstart', startDrawing, {
				passive: false,
			})
			canvasRef.current.addEventListener('touchmove', drawWithRAF, {
				passive: false,
			})
			canvasRef.current.addEventListener('touchend', stopDrawing, {
				passive: false,
			})
			canvasRef.current.addEventListener('mouseleave', stopDrawing)

			return () => {
				if (canvasRef.current) {
					canvasRef.current.removeEventListener('touchstart', startDrawing)
					canvasRef.current.removeEventListener('touchmove', drawWithRAF)
					canvasRef.current.removeEventListener('touchend', stopDrawing)
					canvasRef.current.removeEventListener('mouseleave', stopDrawing)
				}
			}
		}
	}, [context, startDrawing, drawWithRAF, stopDrawing])

	return (
		<div className='box-canvas absolute inset-0 max-h-[80vh] mmd:max-h-[100vh]'>
			{canvasStates.length <= 1 &&
				maskImage == null &&
				!isMaskLoaded &&
				false && (
					<div className='pre-text'>
						<div className='box-pre-anim'>
							<div className='pre-anim'>
								<span className='icon-pointer' />
							</div>
						</div>
						<p>Start painting the clothes</p>
					</div>
				)}
			<canvas
				ref={canvasRef}
				className='hidden max-h-[80vh] mmd:max-h-[100vh]'
				style={{
					touchAction: 'none',
				}}
				onMouseDown={startDrawing}
				onMouseMove={drawWithRAF}
				onMouseUp={stopDrawing}
			/>
			{genReqPending && (
				<div className='absolute z-[41] flex h-full w-full items-center justify-center backdrop-blur transition-all'>
					<img
						src='/icons/loading.svg'
						alt='spinner'
						width={64}
						height={64}
						className='flex max-h-[64px] max-w-[64px] animate-spin'
					/>
				</div>
			)}
		</div>
	)
}

export const uploadImage = async (base64Image, name, custom) => {
	try {
		// Extract file extension from the base64 image
		const extensionMatch = base64Image.match(
			/data:image\/([a-zA-Z0-9]+);base64,/
		)
		if (!extensionMatch) {
			throw new Error('Invalid base64 image format')
		}
		const fileExtension = extensionMatch[1]

		// Convert base64 to Blob
		const byteCharacters = atob(base64Image.split(',')[1])
		const byteArrays = []

		for (let offset = 0; offset < byteCharacters.length; offset += 512) {
			const slice = byteCharacters.slice(offset, offset + 512)
			const byteNumbers = new Array(slice.length)
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i)
			}
			const byteArray = new Uint8Array(byteNumbers)
			byteArrays.push(byteArray)
		}

		const blob = new Blob(byteArrays, { type: `image/${name}${fileExtension}` })

		// Create a File object with the desired name format
		const fileName = `name.${fileExtension}`
		const file = new File([blob], fileName, { type: `image/${fileExtension}` })

		return file
	} catch (error) {
		console.error('Error uploading file:', error)
	}
}

const uniteCanveses = async (mask, orig) => {
	var c = document.createElement('canvas')
	var ctx = c.getContext('2d')
	var imageObj1 = new Image()
	var imageObj2 = new Image()

	var loadImg1 = new Promise((resolve) => {
		imageObj1.onload = resolve
		imageObj1.src = orig
	})

	var loadImg2 = new Promise((resolve) => {
		imageObj2.onload = resolve
		imageObj2.src = mask
	})

	await Promise.all([loadImg1, loadImg2])

	c.width = imageObj1.width
	c.height = imageObj1.height
	ctx.drawImage(imageObj1, 0, 0, imageObj1.width, imageObj1.height)
	ctx.drawImage(imageObj2, 0, 0, imageObj1.width, imageObj1.height)
	return c.toDataURL()
}

const imageDataToDataURL = (imageData) => {
	const tempCanvas = document.createElement('canvas')
	tempCanvas.width = imageData.width
	tempCanvas.height = imageData.height
	const tempCtx = tempCanvas.getContext('2d')
	tempCtx.putImageData(imageData, 0, 0)
	return tempCanvas.toDataURL()
}

function isMobile() {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	)
}

export default Canvas
