import { Link } from 'react-router-dom'

export const Footer = () => {
	return (
		<footer className='w-full'>
			<div className='flex w-full flex-col items-center justify-between gap-5 mmd:flex-row '>
				<nav className='flex w-full max-w-full flex-col gap-5 text-xl mmd:max-w-[400px]'>
					<Link
						to='/privacy'
						onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
						className='flex justify-between gap-3 text-gray transition-all hover:opacity-75'
					>
						<p>Privacy Policy</p>
						<img
							src='/icons/arrowRight.svg'
							alt='arrow right'
							className='h-[28px] w-[10px] opacity-50'
						/>
					</Link>
					<Link
						to='/terms'
						onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
						className='flex justify-between gap-3 text-gray transition-all hover:opacity-75'
					>
						<p>Terms of Service</p>
						<img
							src='/icons/arrowRight.svg'
							alt='arrow right'
							className='h-[28px] w-[10px] opacity-50'
						/>
					</Link>
					<Link
						to='/contact'
						onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
						className='flex justify-between gap-3 text-gray transition-all hover:opacity-75'
					>
						<p>Contact Us</p>
						<img
							src='/icons/arrowRight.svg'
							alt='arrow right'
							className='h-[28px] w-[10px] opacity-50'
						/>
					</Link>
				</nav>
				<div className='hidden flex-col gap-3 px-5 py-5 text-center text-base text-gray opacity-60 llg:flex'>
					<p className='text-[10px]/[12px] mmd:text-[12px]/[14px]'>
						ITAI TECH LTD | 2024 | All rights reserved
					</p>
					<p className='text-[10px]/[12px] mmd:text-[12px]/[14px]'>
						20 Wenlock Road, London, England, N1 7GU
					</p>
					<div className='flex items-center justify-center gap-[8px] pt-[16px]'>
						<img src='/icons/providers/visa.svg' alt='visa' />
						<img src='/icons/providers/mastercard.svg' alt='mastercard' />
					</div>
				</div>
				<ul className='flex w-full max-w-full flex-col gap-5 text-xl mmd:max-w-[400px]'>
					{/* <li>
						<a
							target='_blank'
							href='https://t.me/undresslove'
							className='flex justify-between gap-3 text-gray transition-all hover:opacity-75'
						>
							<p>Telegram</p>
							<img
								src='/icons/arrowRight.svg'
								alt='arrow right'
								className='h-[28px] w-[10px] opacity-50'
							/>
						</a>
					</li> */}
					<li>
						<a
							href={`mailto:support@undress.love`}
							className='flex justify-between gap-3 text-gray transition-all hover:opacity-75'
						>
							<p>support@undress.love</p>
							<img
								src='/icons/arrowRight.svg'
								alt='arrow right'
								className='h-[28px] w-[10px] opacity-50'
							/>
						</a>
					</li>
					<li>
						<a
							href={`https://blog.undress.love/`}
							className='flex justify-between gap-3 text-gray transition-all hover:opacity-75'
						>
							<p>Blog</p>
							<img
								src='/icons/arrowRight.svg'
								alt='arrow right'
								className='h-[28px] w-[10px] opacity-50'
							/>
						</a>
					</li>
					<Link
						to='/refunds'
						onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
						className='flex justify-between gap-3 text-gray transition-all hover:opacity-75'
					>
						<p>Refund Policy</p>
						<img
							src='/icons/arrowRight.svg'
							alt='arrow right'
							className='h-[28px] w-[10px] opacity-50'
						/>
					</Link>
				</ul>
			</div>
			<div className='flex flex-col gap-2 pb-5 pt-10 text-center text-base text-gray llg:hidden'>
				<p className='text-[10px]/[12px] mmd:text-[12px]/[14px]'>
					ITAI TECH LTD | 2024 | All rights reserved
				</p>
				<p className='text-[10px]/[12px] mmd:text-[12px]/[14px]'>
					20 Wenlock Road, London, England, N1 7GU
				</p>
				<div className='flex items-center justify-center gap-[8px] pt-[16px]'>
					<img src='/icons/providers/visa.svg' alt='visa' />
					<img src='/icons/providers/mastercard.svg' alt='mastercard' />
				</div>
			</div>
		</footer>
	)
}
