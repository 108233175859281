import { useState } from 'react'
import { EmailAuth } from '../modals/EmailAuth'

export function EmailLoginButton() {
	const [isHovered, setIsHovered] = useState(false)
	const [activeModal, setActiveModal] = useState(undefined)

	return (
		<>
			<button
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				className='flex h-[88px] w-full items-center justify-between gap-[16px] self-stretch rounded-[44px] bg-[#121212] pl-[16px] pr-[24px] text-start transition-all hover:bg-[#232323]'
				onClick={() => setActiveModal('email')}
			>
				<div className='flex items-center justify-center gap-[16px]'>
					<div className='flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#FFFFFF]'>
						<img
							className='h-[20px] w-[26px]'
							src='/icons/mailLogo.svg'
							alt='mail logo'
						/>
					</div>
					<div className='flex flex-col'>
						<p className='text-[12px] text-[#FFFFFF80]'>login with</p>
						<h5 className='text-[16px] font-semibold'>Email</h5>
					</div>
				</div>
				{isHovered ? (
					<h5 className='text-[14px] text-[#FFFFFF]'>Continue</h5>
				) : (
					<img
						src='/icons/keyIcon.svg'
						alt='key icon'
						className='h-[23.95px] w-[12.5px]'
					/>
				)}
			</button>
			{activeModal === 'email' && <EmailAuth setActiveModal={setActiveModal} />}
		</>
	)
}
