'use client'

import { OrangeButton } from '~/shared/ui/button/OrangeButton'
import React from 'react'

interface ConfirmSignUpProps {
	setActiveModal: (modal?: any) => void
}

export const ConfirmSignUp = ({ setActiveModal }: ConfirmSignUpProps) => {
	return (
		<>
			<div className='flex items-start justify-between text-[24px] font-bold'>
				Verify your email
				<button onClick={() => setActiveModal(undefined)}>
					<img
						src='/icons/close-alt.svg'
						alt='close'
						className='min-w-[20px] pt-1 opacity-40 transition-all hover:opacity-60'
						width={24}
						height={24}
					/>
				</button>
			</div>
			<div className='flex flex-col items-center justify-center gap-[16px] pb-[24px] pt-[16px] text-center text-xs'>
				<img
					src='/icons/providers/email.svg'
					width={64}
					height={64}
					alt='email icon'
				/>
				<span className='rounded-full border-2 border-white bg-white/[12%] px-[8px] py-[4px] text-[13px] font-bold'>
					Check your email
				</span>
				<p className='opacity-60'>
					We will send you a verification link on your email.
				</p>
			</div>
			<OrangeButton
				className='mt-[8px] flex justify-center disabled:bg-[#1d1d1d]'
				onClick={() => setActiveModal(undefined)}
			>
				Close
			</OrangeButton>
		</>
	)
}
