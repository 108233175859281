import { sendImageGenerationRequest } from '~/api/image/ImageGenerationRequest'
import { UserPlan } from '~/lib/traits/UserPlan'
import { plans } from '~/lib/utils'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { saveAs } from 'file-saver'
import { PromocodeModal } from '../modals/PromocodeModal'
import PromptLockedModal from '../modals/modal_promptLocked'
import GenerateButton from './components/generateButton'
import Queue from './components/queue'
import RefferalBanner from './components/refferalBanner'
import Result from './components/result'
import ModePicker from './components/sliders/modePicker'
import QualitySlider from './components/sliders/qualitySlider'
import ToolsHeader from './components/toolsHeader'
import Undressing from './components/undressing'
import UploadNewImageButton from './components/uploadNewImageButton.jsx'

export default function Tools({
	setShowTips,
	setHideRefs,
	maskImage,
	initialEta,
	eta,
	progress,
	onUndressButton,
	originalImage,
	setOriginalImage,
	queuePosition,
	resultReady,
	tempImage,
	resolution,
	setResolution,
	userInfo,
	selectedMode,
	setSelectedMode,
	isNewFileRequested,
	setNewFileRequested,
	isDemo,
}) {
	const navigate = useNavigate()
	const [showModePicker, setShowModePicker] = useState(false)
	const [isPromptLocked, setIsPromptLocked] = useState(false)
	const [selectedType, setSelectedType] = useState('')
	const [showPromo, setShowPromo] = useState(false)

	const [allPrompts, setAllPrompts] = useState([])

	useEffect(() => {
		async function fetchUserPrompts() {
			setAllPrompts(['LINGERIE', 'UNDRESS', 'BIKINI', 'SHIBARI', 'BDSM', 'SEX'])
		}

		fetchUserPrompts()
	}, [])

	const downloadImage = () => {
		fetch(tempImage)
			.then((response) => response.blob())
			.then((blob) => {
				saveAs(blob, 'result.jpg')
			})
			.catch((error) => {
				console.error('Error downloading image:', error)
			})
	}

	return (
		<div className='flex-start flex w-full flex-col mmd:w-[420px]'>
			<div className='flex flex-col gap-[20px]'>
				<ToolsHeader userInfo={userInfo} UserPlan={UserPlan} />
				{((queuePosition === null && maskImage === null) || isDemo) && (
					<div className='flex flex-col gap-5'>
						<QualitySlider
							navigate={navigate}
							userInfo={userInfo}
							resolution={resolution}
							setResolution={setResolution}
						/>
						<ModePicker
							userPlan={userInfo.plan.displayName}
							setIsPromptLocked={setIsPromptLocked}
							allPrompts={allPrompts}
							setSelectedType={setSelectedType}
							selectedMode={selectedMode}
							setSelectedMode={setSelectedMode}
							setShowModePicker={setShowModePicker}
						/>

						{originalImage && (
							<GenerateButton
								originalImage={originalImage}
								onUndressButton={onUndressButton}
							/>
						)}

						<UploadNewImageButton setOriginalImage={setOriginalImage} />
					</div>
				)}
				{progress === -1 && queuePosition !== null && queuePosition >= 0 && (
					<Queue
						userInfo={userInfo}
						UserPlan={UserPlan}
						navigate={navigate}
						genEta={initialEta}
					/>
				)}
				{progress !== -1 && progress < 100 && (
					<>
						<Undressing progress={progress} eta={eta} initialEta={initialEta} />
					</>
				)}
				{resultReady && (
					<Result
						downloadImage={downloadImage}
						originalImage={originalImage}
						userInfo={userInfo}
						sendImageGenerationRequest={sendImageGenerationRequest}
						navigate={navigate}
						setShowTips={setShowTips}
						isNewFileRequested={isNewFileRequested}
						setNewFileRequested={setNewFileRequested}
					/>
				)}
				<button
					className='h-[75px] flex-grow cursor-pointer rounded-full bg-[#9747ff] font-semibold transition-all hover:scale-[98%]'
					onClick={() => {
						setShowPromo(true)
					}}
				>
					<h5 className='h-auto grow px-4 text-center text-[22px] font-semibold'>
						Activate promocode
					</h5>
				</button>
				<RefferalBanner setHideRefs={setHideRefs} />
				{isPromptLocked && (
					<PromptLockedModal
						navigate={navigate}
						setIsPromptLocked={setIsPromptLocked}
					/>
				)}
				{showPromo && <PromocodeModal setActiveModal={setShowPromo} />}
			</div>
		</div>
	)
}
