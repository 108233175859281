import { useTranslation } from 'react-i18next'

export default function GenerateButton({ originalImage, onUndressButton }) {
	const { t } = useTranslation()
	return (
		<div className='flex w-full rounded-full bg-[#171717]'>
			<button
				disabled={!originalImage}
				className='h-[75px] flex-grow cursor-pointer rounded-full bg-orange'
				onClick={onUndressButton}
			>
				{/* disabled:bg-[#171717] disabled:text-[#9c9c9c] */}
				<h5 className='h-auto grow px-4 text-center text-[22px] font-semibold'>
					{t('generate')}
				</h5>
			</button>
		</div>
	)
}
