import { getWebsiteNameCapitalized } from '~/lib/utils'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'

export default function ToolsHeader({ userInfo, UserPlan }) {
	const { t } = useTranslation()
	const { isDemo } = useSelector((state) => state.autoMaskReducer)

	return (
		<>
			<div className='hidden w-full items-center justify-between mmd:flex'>
				<h4 className='cursor-pointer text-[28px] font-bold transition-all active:scale-[98%]'>
					{getWebsiteNameCapitalized()}
				</h4>
				<img
					src='logo.svg'
					className='h-auto w-[45px] cursor-pointer transition-all active:scale-[98%]'
					alt='logo'
				/>
			</div>
			<Link
				to='/plan'
				className='flex w-full items-center justify-between transition-all hover:opacity-50'
			>
				{userInfo && UserPlan && (
					<>
						<div className='flex flex-col items-start'>
							<span>
								{userInfo.plan === undefined
									? UserPlan.NO.displayName
									: userInfo.plan.displayName}{' '}
								plan
							</span>
							<h4 className='text-[22px] font-semibold text-[#808080]'>
								{isDemo && Cookies.get('token') === undefined
									? 3
									: userInfo.generationsLeft}{' '}
								{t('generations_left')}
							</h4>
						</div>
						<span className='rounded-full bg-[#9747ff]'>
							<img
								src='/icons/diamondIcon.svg'
								alt='diamond icon'
								className='m-3'
							/>
						</span>
					</>
				)}
			</Link>
		</>
	)
}
