function formatTimeLeft(milliseconds) {
	const totalSeconds = Math.floor(milliseconds / 1000)
	const minutes = Math.floor(totalSeconds / 60)
	const seconds = totalSeconds % 60
	const paddedMinutes = String(minutes).padStart(2, '0')
	const paddedSeconds = String(seconds).padStart(2, '0')
	return `${paddedMinutes}:${paddedSeconds}`
}

export default function Undressing({ progress, initialEta, eta }) {
	return (
		<div className='line-frame231'>
			<span className='line-text06'>
				<span>Enhancing...</span>
			</span>

			<div className='box-progressbar'>
				<div className='bg-progressbar prog-pro-plan prog-donate'>
					<div
						className='progressbar'
						style={{
							width: `${progress}%`,
						}}
					></div>
				</div>

				<div className='box-init'>
					<p>Progress</p>
					<p>{progress}%</p>
				</div>
			</div>

			<span className='line-text08'>
				<span>
					<span>
						{eta > 1
							? `${formatTimeLeft(eta)} seconds left until the end`
							: 'Finishing'}
					</span>
				</span>
			</span>
		</div>
	)
}
