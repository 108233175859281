export default function RefferalBanner({ setHideRefs }) {
	return (
		<button
			className='mt-[12px] flex flex-col overflow-hidden rounded-[22px] bg-[#171717] text-[22px] transition-all hover:bg-[#292929] active:scale-95'
			onClick={() => {
				setHideRefs(false)
			}}
		>
			<img
				draggable='false'
				src='/playground_assets/referal.png'
				className='h-[120px] w-full object-cover lg:h-[80px]'
			/>
			<p className='w-full p-[12px] text-center'>Earn credits for invites</p>
		</button>
	)
}
