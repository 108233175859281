import {
	ConfirmRegisterViaMailParams,
	RegisterViaMailParams,
} from '~/lib/types'
import { api } from '~/shared/api'

export async function registerViaMail(params: RegisterViaMailParams) {
	const response = await api.post(`/login/local/register`, params)

	return response.data
}

export async function confirmRegistrationViaMail(
	params: ConfirmRegisterViaMailParams
) {
	const response = await api.post(`/login/local/register/confirm`, params)

	return response.data
}

export async function loginViaMail(params: RegisterViaMailParams) {
	const response = await api.post(`/login/local/login`, params)

	return response.data
}
