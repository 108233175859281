const privacy = [
	{
		title: '1. Introduction',
		text: 'Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website.',
	},
	{
		title: '2. Information We Collect',
		text: 'We save your email address when you register for an account on our website. By registering in our website you accept and agree all our inner rules.',
	},
	{
		title: '3. How We Use Your Information',
		text: 'We use your email address to send you email updates and for account management purposes.',
	},
	{
		title: '4. Third-Party Payment Processors',
		text: "When you make a purchase on our website, your payment information is processed by a third-party payment service provider. We do not have access to or store any payment information. Please refer to the third-party payment service provider's terms and conditions and privacy policy for more information.",
	},
	{
		title: '5. Cookies',
		text: 'We use cookies to enhance your experience on our website. Cookies are small files that are stored on your device and used to remember your preferences, improve website performance, and provide personalized content.',
	},
	{
		title: '6. Security',
		text: 'We take reasonable measures to protect your account from unauthorized access, use, or disclosure. However, please be aware that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to protect your personal information, we cannot guarantee its absolute security and do not carry any responsibility for any incidents which may occur.',
	},
	{
		title: '7. Sharing Your Information',
		text: 'We do not sell, trade, or rent your personal information to third parties. We may share your information with our trusted service providers who assist us in operating our website, conducting our business, or providing services to you, so long as those parties agree to keep this information confidential. We may also disclose your information when required by law, to enforce our site policies, or to protect our rights, property, or safety, as well as the rights, property, or safety of others.',
	},
	{
		title: '8. Third-Party Websites',
		text: 'Our website may contain links to third-party websites that are not operated by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites. We encourage you to review the privacy policies of each website you visit.',
	},
	{
		title: '9. Changes to the Privacy Policy',
		text: 'We may update this Privacy Policy from time to time. Any changes shall come into effect by posting the new Privacy Policy on our website. Your continued use of our website constitutes acceptance of any changes to the Privacy Policy.',
	},
	{
		title: '10. Contact Us',
		text: 'If you have any questions or concerns about this Privacy Policy, please contact us at support@undress.love.',
	},
]

export default privacy
