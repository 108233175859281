import { createPersonalTask } from '~/api/image/PersonalTask.js'
import { sendUserInfoRequest } from '~/api/user/UserInfoRequestPacket'
import { markCurrentTaskAsViewed } from '~/apiNEW/generation/PersonalTask'
import AuthorizationContext from '~/lib/authorizationContext'
import { ImageQuality, qts } from '~/lib/traits/ImageQuality'
import { UserPlan } from '~/lib/traits/UserPlan'
import { getWebsiteNameCapitalized } from '~/lib/utils'
import { validateDataOnReGenerateImage } from '~/lib/utils'
import { Footer } from '~/shared/ui/footer'
import { useContext, useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Cookies from 'js-cookie'
import ModalHelper from '../modals/modal_helper.jsx'
import InappropriatePhotoWarning from '../modals/modal_inappropriatePhotoWarning.jsx'
import ModalReferral from '../modals/modal_referal'
import ModalWithText from '../modals/modal_simpleWithText'
import Canvas, { uploadImage } from './canvas'
import SocialLink from './components/socialLinkElement'
import './edit.css'
import Tools from './tools'

const Edit = () => {
	const { authorized, setAuthorized } = useContext(AuthorizationContext)
	const { isDemo: demo } = useSelector((state) => state.autoMaskReducer)

	const [userInfo, setUserInfo] = useState({
		plan: UserPlan.NO,
		generationsLeft: 0,
		inviteCode: '',
		refsInvited: 0,
	})

	const navigate = useNavigate()
	const [hideCanvas, setHideCanvas] = useState(false)
	const [originalImage, setOriginalImage] = useState(null)
	const [maskImage, setMaskImage] = useState(null)
	const [errorMessage, setErrorMessage] = useState('')
	const [generationObjectAge, setGenerationObjectAge] = useState(0)
	const [queuePosition, setQueuePosition] = useState(null)
	const [generationProgress, setProgress] = useState(-1)
	const [eta, setEta] = useState(0)
	const [maxEta, setMaxEta] = useState(0)
	const [initialEta, setInitialEta] = useState(0)
	const [tempImage, setTempImage] = useState(null)
	const [resultReady, setResultReady] = useState(false)
	const [brushSize, setBrushSize] = useState(10)
	const [hideRefs, setHideRefs] = useState(true)
	const [showBalanceModal, setBalanceModal] = useState(false)
	const [isLoaded, setIsLoaded] = useState(false)
	const [isWarningVisible, setIsWariningVisible] = useState(false)
	const [isDemo, setIsDemo] = useState(false)
	const [selectedMode, setSelectedMode] = useState('LINGERIE')
	const [resolution, setResolution] = useState(qts[ImageQuality.SD])
	const [undo, setUndo] = useState(false)
	const [showTip, setShowTip] = useState(false)
	const [currentTool, setCurrentTool] = useState('brush')
	const [isNewFileRequested, setNewFileRequested] = useState(false)
	const [genReqPending, setGenReqPending] = useState(false)

	useEffect(() => {
		if (!demo && !Cookies.get('token')) {
			navigate('/')
		}
	}, [])

	useEffect(() => {
		if (authorized && userInfo?.userId) {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: 'aevent',
				event_name: 'page_loaded',
				user_id: userInfo.userId,
			})

			if (window.umami) {
				window.umami.track('Page loaded', {
					user_id: userInfo.userId,
				})
			}
		}
	}, [userInfo?.userId])

	useEffect(() => {
		const checkAuthorization = async () => {
			if (Cookies.get('token') !== undefined) {
				await sendUserInfoRequest()
					.then(() => {
						setAuthorized(true)
					})
					.catch((e) => {
						setAuthorized(false)
						navigate('/')
					})
			} else {
				setAuthorized(false)
				navigate('/')
			}
		}

		checkAuthorization()
	}, [])

	useEffect(() => {
		const getGAClientId = () => {
			const gaCookie = Cookies.get('_ga')
			if (gaCookie) {
				const clientId = gaCookie.replace('GA1.1.', '')
				return clientId
			}
			return null
		}

		const sendImageData = async () => {
			let response
			const gaClientId = getGAClientId()

			try {
				setGenReqPending(true)
				const originalId = await uploadImage(originalImage, 'original')

				sessionStorage.setItem('resolutionStorage', resolution)
				sessionStorage.setItem('selectedModeStorage', selectedMode)

				console.log(gaClientId)

				response = await createPersonalTask(
					originalId,
					selectedMode,
					gaClientId
				)

				setTempImage(originalImage)
			} catch (e) {
				// console.log(e)
			} finally {
				setGenReqPending(false)
			}

			const queueData = response
			setQueuePosition(queueData.queue_pos)
			setEta(queueData.estimated_wait_time)
			setInitialEta(queueData.estimated_wait_time)
			setMaxEta(maxEta > initialEta ? maxEta : initialEta)
			setHideCanvas(true)
		}

		if (
			(maskImage !== null && maskImage !== undefined && !hideCanvas) ||
			(sessionStorage.getItem('generateAgain') &&
				validateDataOnReGenerateImage(userInfo))
		) {
			sendImageData()
		}
	}, [maskImage, authorized])

	useEffect(() => {
		if (!isDemo) {
			const requestInfo = async () => {
				try {
					const response = await sendUserInfoRequest()
					if (response.status !== 200) {
						// TODO: show error
						return
					}
					const data = response.data
					const localUserInfo = {
						plan: UserPlan.get(
							data.subscription ? data.subscription.plan_id : 'no'
						),
						generationsLeft: data.credits,
						inviteCode: data.invite_code,
						refsInvited: data.referals,
						email: data.email,
						taskStatus: data?.current_task,
						userId: data.user_id,
					}

					setUserInfo(localUserInfo)
					setIsLoaded(true)
					// Update picture if WS closed
					const gen = data.current_task
					if (gen && !isNewFileRequested) {
						const generateAgain =
							sessionStorage.getItem('generateAgain') === 'true'
						let originalImageLocal
						if (generateAgain) {
							originalImageLocal = localStorage.getItem('originalImage')
							sessionStorage.setItem('generateAgain', 'false')
							localStorage.setItem('originalImage', '')
						}

						if (originalImageLocal) {
							if (!tempImage) setTempImage(originalImageLocal)
							if (!originalImage) setOriginalImage(originalImageLocal)
							if (!maskImage) setMaskImage(originalImageLocal)
						}

						if (gen.queue_pos !== undefined) {
							setQueuePosition(gen.queue_pos)
							if (gen.estimated_wait_time) setEta(gen.estimated_wait_time)
						}

						setHideCanvas(true)
						if (gen.input_url && !gen.result_url) {
							setTempImage(gen.input_url)
						}
						if (gen.input_url) setOriginalImage(gen.input_url)
						if (gen.input_url) setMaskImage(gen.input_url)
						if (gen.progress) {
							if (gen.progress > generationProgress) setProgress(gen.progress)
						}
						if (gen.estimated_wait_time) {
							if (gen.estimated_wait_time !== initialEta) {
								setEta(gen.estimated_wait_time)
								setInitialEta(gen.estimated_wait_time)
								setMaxEta(maxEta > initialEta ? maxEta : initialEta)
							}
						}
						if (gen.result_url) {
							setResultReady(true)
							setOriginalImage(gen.result_url)
							setTempImage(gen.result_url)
							setMaskImage(gen.result_url)
						}
						// if (gen.errorMessage) {
						// 	setErrorMessage(gen.errorMessage)
						// }
						// if (gen.age) {
						// 	setGenerationObjectAge(gen.age)
						// }
					}

					sessionStorage.setItem('userInfo', JSON.stringify(localUserInfo))
				} catch {
					setIsDemo(demo ? true : false)
					setIsLoaded(true)
				}
			}

			requestInfo()

			const intervalId = setInterval(() => {
				requestInfo()
			}, 5000)

			return () => {
				clearInterval(intervalId)
			}
		}
	}, [setInitialEta, initialEta, isDemo])

	const handleUndressButton = () => {
		Cookies.set('auth_redirect', '/edit')
		if (isDemo) {
			Cookies.set('generate_before_auth', 'true')
			navigate('/auth')
			return
		}
		if (userInfo.generationsLeft > 0) {
			setMaskImage(undefined)
			Cookies.remove('generate_before_auth')
		} else {
			setBalanceModal(false)
		}
	}

	useEffect(() => {
		if (
			(sessionStorage.getItem('generateAgain') &&
				validateDataOnReGenerateImage(userInfo)) ||
			Cookies.get('generate_before_auth') === 'true'
		) {
			handleUndressButton()
		}
	}, [userInfo])

	useEffect(() => {
		const markTaskAndNavigate = async () => {
			if (userInfo?.taskStatus?.status === 'FAILED') {
				if (userInfo?.taskStatus?.error_message === 'AGE_VIOLATION') {
					toast.error(
						'The uploaded image must contain only individuals who are 18 years of age or older.'
					)
				} else toast.error('Task failed, try again.')

				await markCurrentTaskAsViewed()
				setTimeout(() => {
					navigate(0)
				}, 2000)
			}
		}

		markTaskAndNavigate()
	}, [userInfo?.taskStatus])

	return (
		<HelmetProvider>
			<div className='min-h-screen bg-black'>
				<Helmet>
					<title>Edit</title>
				</Helmet>
				<div className='flex h-auto w-full flex-shrink-0 flex-col items-start gap-4 overflow-hidden bg-black p-[16px] mmd:p-[24px]'>
					<div className='flex flex-col gap-[24px] self-stretch'>
						<div className='flex items-center gap-[0.5rem] self-stretch text-[1.5rem] mmd:hidden'>
							<div className='flex h-[4rem] flex-col items-center justify-center gap-[0.25rem] rounded-[0.75rem]'>
								<img className='h-[4rem]' src='logo_rounded.svg' />
							</div>
							{getWebsiteNameCapitalized()}
						</div>
						<div className='flex flex-col justify-start gap-8 self-stretch'>
							<div className='flex w-full flex-col gap-8 mmd:flex-row'>
								<Canvas
									genReqPending={genReqPending}
									isWarningVisible={isWarningVisible}
									setIsWariningVisible={setIsWariningVisible}
									progress={generationProgress}
									maskImage={maskImage}
									tempImage={tempImage}
									setMaskImage={setMaskImage}
									queuePosition={queuePosition}
									originalImage={originalImage}
									setOriginalImage={setOriginalImage}
									setUndo={setUndo}
									isUndo={undo}
									brushSize={brushSize}
									hideCanvas={hideCanvas}
									resultReady={resultReady}
									setShowTip={setShowTip}
									errorMessage={errorMessage}
									generationObjectAge={generationObjectAge}
									currentTool={currentTool}
									setCurrentTool={setCurrentTool}
									userInfo={userInfo}
									isLoaded={isLoaded}
									isNewFileRequested={isNewFileRequested}
									isDemo={isDemo}
								/>
								<Tools
									eta={eta}
									progress={generationProgress}
									onUndressButton={handleUndressButton}
									maskImage={maskImage}
									setMaskImage={setMaskImage}
									queuePosition={queuePosition}
									resultReady={resultReady}
									tempImage={tempImage}
									originalImage={originalImage}
									resolution={resolution}
									setResolution={setResolution}
									userInfo={userInfo}
									setOriginalImage={setOriginalImage}
									setHideRefs={setHideRefs}
									setShowTips={setShowTip}
									selectedMode={selectedMode}
									setSelectedMode={setSelectedMode}
									initialEta={initialEta}
									setNewFileRequested={setNewFileRequested}
									isNewFileRequested={isNewFileRequested}
									isDemo={isDemo}
								/>
							</div>
							<Footer />
						</div>
					</div>
				</div>
				{!hideRefs && (
					<ModalReferral userInfo={userInfo} setHideRefs={setHideRefs} />
				)}
				{showTip && <ModalHelper setShow={setShowTip} show={showTip} />}
				{isWarningVisible && (
					<InappropriatePhotoWarning
						setIsWariningVisible={setIsWariningVisible}
						originalImage={originalImage}
						navigate={navigate}
					/>
				)}
			</div>
		</HelmetProvider>
	)
}

export const getUserInfo = () => {
	const storedState = sessionStorage.getItem('userInfo')
	return JSON.parse(storedState)
}

export default Edit
