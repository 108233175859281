import { UserInfoResponse } from '~/lib/types'
import { api } from '~/shared/api'

export async function sendUserInfoRequest(): Promise<UserInfoResponse> {
	const response = await api.get<UserInfoResponse>('/user/info', {})

	return response.data
}

export async function checkPromocode(code: string): Promise<any> {
	const response = await api.get<any>(`/promocode/${code}`, {})

	return response
}

export async function activatePromocode(code: string): Promise<any> {
	const response = await api.post<any>(`/promocode/${code}/activate`, {})

	return response
}
