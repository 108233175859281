import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'

const tips = [
	"You can give any pose a try, but a suitable pose can significantly enhance undressing results. If unsatisfied, don't hesitate to switch things up!",
	'Ensure the clothing to be removed is painted fully, including limps and such. When in doubt, give the editor more space rather than less for optimal results!',
	"Got a good result with minor bugs? Don't worry! Just upload it again and repaint the troubled spots for a perfect finish.",
	"If parts of the clothing still persist, paint bigger outline. If still unsuccessful, switch pictures. Repeated attempts on the same picture won't yield different results.",
]
const ModalHelper = ({ show, setShow }) => {
	useEffect(() => {
		setShow(false)
	}, [setShow])

	const [index, setIndex] = useState(0)
	return (
		<>
			{' '}
			{show && (
				<div className='fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center p-0 transition-all animate-in fade-in-5 slide-in-from-top-8 duration-500 md:p-3'>
					<div className='absolute bottom-0 flex min-w-full max-w-[600px] flex-col justify-center rounded-xl bg-[#171717] p-6 shadow-lg md:static md:min-w-fit'>
						<div className='flex flex-col items-center gap-5'>
							<img
								className='max-w-[350px] rounded-lg'
								src='/bodytype/info.png'
							/>

							<p className='max-w-[600px] text-center text-[20px] md:max-w-[350px]'>
								{tips[index]}
							</p>
						</div>

						<div className='box-stages py-8'>
							<span className={index === 0 ? 'stages' : ''} />
							<span className={index === 1 ? 'stages' : ''} />
							<span className={index === 2 ? 'stages' : ''} />
							<span className={index === 3 ? 'stages' : ''} />
							<span className={index === 4 ? 'stages' : ''} />
						</div>

						<div className='flex w-full items-center justify-between gap-3'>
							<button
								disabled={index === 0}
								className='flex grow items-center rounded-full bg-orange px-[26px] py-[14px] disabled:bg-[#393939]'
								onClick={() => {
									setIndex(index - 1)
								}}
							>
								<img
									className='w-[14px]'
									src='icons/arrowLeft.svg'
									alt='arrow left icon'
								/>
								<p className='grow text-[22px]'>Back</p>
							</button>

							<div></div>

							<button
								className='flex grow items-center rounded-full bg-orange px-[26px] py-[14px]'
								onClick={() => {
									setIndex(index + 1)
									if (index >= tips.length - 1) {
										setShow(false)
										Cookies.set('ignorehelp', true)
									}
								}}
							>
								<p className='grow text-[22px]'>Next</p>
								<img
									className='h-auto w-[14px]'
									src='/icons/arrowRight.svg'
									alt='arrow right icon'
								/>
							</button>
						</div>
					</div>
				</div>
			)}
			<div className='fixed left-0 top-0 z-10 h-full w-full bg-black opacity-60 animate-in fade-in-5 duration-500'></div>
		</>
	)
}
export default ModalHelper
