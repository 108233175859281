import { SubPlanTypes } from '~/lib/types'

export default function PlanCard({
	gold,
	planName,
	allPlans,
	period,
	userInfo,
	credits,
	features,
	setSelectedPlan,
}: any) {
	const userDiscounts = userInfo?.promocode?.discounts || {}

	function getProductPrice(productID: string) {
		const product = allPlans.find((p: any) => p.id === productID)
		if (product) {
			return product.price
		} else {
			return null
		}
	}

	const planNameLower = planName.toLowerCase()
	const planBaseID = `${planNameLower}_plan`
	const productID =
		period && period !== 1 ? `${planNameLower}_plan_${period}m` : planBaseID

	const planPrice = getProductPrice(`${productID}_u_love`)
	const planPriceBase = getProductPrice(`${planBaseID}_u_love`)

	const hasUserDiscount = (planId: string): boolean => {
		return userDiscounts[planId as SubPlanTypes] !== undefined
	}

	const getDiscountedPrice = (planId: string, price: number) => {
		if (hasUserDiscount(planId)) {
			const discount = userDiscounts[planId as SubPlanTypes]
			if (discount?.percent !== undefined) {
				return price * (1 - discount.percent / 100)
			}
			if (discount?.amount !== undefined) {
				return price - discount.amount
			}
		}
		return price
	}

	return (
		<div className='scroll-snap-align-start m-2 flex w-[19rem] flex-col justify-between gap-[32px] rounded-3xl bg-[#171717] p-[20px] md:w-[402px]'>
			<div className='flex justify-between'>
				<div className='flex flex-col'>
					<h4
						className={`text-[28px] ${
							gold ? 'text-[#ffcb46]' : 'text-[#aa71f4]'
						}`}
					>
						{planName} Plan
					</h4>
					<p className='text-[12px] text-sm text-[#8b8b8b]'>
						{credits} credits (lifetime)
					</p>
				</div>
				<div
					className={`flex h-[50px] w-[50px] items-center justify-center rounded-full ${
						gold ? 'bg-[#e0a100]' : 'bg-[#9747ff]'
					}`}
				>
					<img
						src='/icons/diamondIcon.svg'
						alt='diamond icon'
						className='h-6 w-6'
					/>
				</div>
			</div>
			<ul className='flex h-[218px] w-full flex-col items-start gap-4'>
				{features?.map((feature: string) => (
					<li
						key={feature}
						className='h-auto w-[290px] text-left text-[16px] text-[#8b8b8b]'
					>
						{feature}
					</li>
				))}
				{planName.toUpperCase() == 'PRO' && (
					<li className='h-auto bg-gradient-to-br from-[#ff8c00] via-[#ff7801] to-[#ff3915] bg-clip-text text-[16px] text-transparent'>
						Undress Mode
					</li>
				)}
			</ul>
			<button
				onClick={() => {
					window.dataLayer = window.dataLayer || []
					window.dataLayer.push({
						event: 'aevent',
						event_name: 'click_on_plan',
						plan: planName,
						period: period,
					})

					if (window.umami) {
						window.umami.track('Click on plan', {
							plan: planName,
							pariod: period,
						})
					}

					setSelectedPlan(`${productID}_u_love`)
				}}
				className={`flex h-[64px] w-full shrink-0 justify-center self-stretch rounded-full ${
					gold
						? 'bg-[#e0a100] hover:bg-[#ffbb0f]'
						: 'bg-[#9747ff] hover:bg-[#a865ff]'
				} p-5 text-[16px] transition-all`}
			>
				{period !== 1 && (
					<span className='mr-3 line-through opacity-50'>
						{planPrice?.currency_symbol}
						{(planPriceBase?.amount * period * 1.1).toFixed(2)}
					</span>
				)}
				<p>
					{hasUserDiscount(`${productID}_u_love`) ? (
						<>
							{planPrice?.currency_symbol}
							{getDiscountedPrice(
								`${productID}_u_love`,
								planPrice?.amount
							).toFixed(2)}
						</>
					) : (
						<>
							{planPrice?.currency_symbol}
							{(planPrice?.amount).toFixed(2)}
						</>
					)}
				</p>
			</button>

			<div className='flex justify-center self-stretch text-center text-sm font-semibold leading-tight text-white text-opacity-50'>
				{hasUserDiscount(`${productID}_u_love`) ? (
					<span className='rounded-full bg-[#a32dff] px-[8px] py-[6px] text-[12px] font-bold text-white'>
						Promo applied
					</span>
				) : (
					<>
						{period !== 1 && (
							<>
								For a total {planPrice?.currency_symbol}
								{(planPrice?.amount / period).toFixed(2)} /m
							</>
						)}
					</>
				)}
			</div>
		</div>
	)
}
