import React, { useEffect, useState } from 'react'

const SimpleModal = ({
	additionElement,
	isClosable = true,
	extraCloseAction,
}) => {
	const [isVisible, setIsVisible] = useState(true)
	const [shouldRender, setShouldRender] = useState(true)
	const [animationType, setAnimationType] = useState(
		'animate-in fade-in-5 slide-in-from-top-8'
	)
	const handleClick = () => {
		setIsVisible(false)
		if (extraCloseAction) {
			extraCloseAction()
		}
	}
	useEffect(() => {
		if (!isVisible) {
			setAnimationType('animate-out fade-out-5 slide-out-from-top-8')
		}
	}, [isVisible])

	const onAnimationEnd = () => {
		if (!isVisible) {
			setShouldRender(false)
		}
	}

	return (
		<>
			{shouldRender && (
				<>
					{isClosable && (
						<div
							className={`absolute z-10 h-full w-full bg-[#202020]/90 backdrop-blur-[32px] animate-in fade-in-5 duration-500`}
						></div>
					)}
					<div
						onAnimationEnd={onAnimationEnd}
						className={`popup left-0 top-0 z-[1000] flex h-full w-full items-center justify-center p-0 ${animationType} pointer-events-auto duration-500 md:p-3`}
					>
						{isClosable && (
							<div
								className='absolute right-[2.7rem] top-[2.7rem] h-8 w-8 gap-2.5'
								onClick={handleClick}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='26'
									height='28'
									viewBox='0 0 26 28'
									fill='none'
								>
									<path
										d='M2.78199 27.4401L0.227539 24.7521L10.4454 14.0001L0.227539 3.24806L2.78199 0.560059L12.9998 11.3121L23.2176 0.560059L25.7721 3.24806L15.5543 14.0001L25.7721 24.7521L23.2176 27.4401L12.9998 16.6881L2.78199 27.4401Z'
										fill='white'
									/>
								</svg>
							</div>
						)}
						{additionElement}
					</div>
				</>
			)}
		</>
	)
}

export default SimpleModal
