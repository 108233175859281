import { getWebsiteName } from '~/lib/utils'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SocialLink from '../edit/components/socialLinkElement'
import ModalWithText from './modal_simpleWithText'

export const advertismentModals = ({ userInfo }) => {
	const [copied, setCopied] = useState(false)
	const navigate = useNavigate()
	return [
		userInfo.plan.displayName !== 'pro' && (
			<ModalWithText
				header={'Unlock Premium Features Now!'}
				description={
					'Don’t wait in line - explore our subscription plans and enjoy immediate generations with premium features.'
				}
				extraElement={
					<button
						className='button flex w-full justify-center gap-2 text-base md:text-2xl'
						onClick={() => window.open('/plan', '_blank')}
					>
						Explore Plans
					</button>
				}
				isClosable={false}
			/>
		),
		<ModalWithText
			header={'Earn with Our Referral Program!'}
			description={
				'Invite your friends and earn rewards when they sign up using your unique referral link.'
			}
			extraElement={
				<div className='flex w-full flex-col justify-center gap-2 text-base md:text-2xl'>
					<div className='relative flex w-full items-center pt-8'>
						<div className='flex w-full flex-col gap-2'>
							<input
								readOnly='readonly'
								className={`w-full cursor-pointer rounded-full border-2 bg-transparent px-6 py-4 font-bold outline-none transition-all ${
									copied
										? ' border-orange text-center text-[22px] text-white'
										: 'border-[#2e2e2e] text-[22px] text-[#898989] hover:border-[#393939] active:scale-95'
								} transition-all active:border-orange`}
								value={
									copied
										? 'Copied!'
										: `https://${getWebsiteName()}/ref/` + userInfo.inviteCode
								}
								onClick={() => {
									const link = `https://${getWebsiteName()}/ref/${
										userInfo.inviteCode
									}`

									window.dataLayer = window.dataLayer || []
									window.dataLayer.push({
										event: 'aevent',
										event_name: 'copy ref',
									})

									if (window.umami) {
										window.umami.track('Copy ref')
									}

									navigator.clipboard.writeText(link)
									setCopied(true)
									setTimeout(() => {
										setCopied(false)
									}, 1500)
								}}
							/>
						</div>
					</div>
					<button
						className='button flex w-full flex-col justify-center gap-2 text-base md:text-2xl'
						onClick={() => {
							const link = `https://${getWebsiteName()}/ref/${
								userInfo.inviteCode
							}`
							navigator.clipboard.writeText(link)
							setCopied(true)
							setTimeout(() => {
								setCopied(false)
							}, 1500)
						}}
					>
						Copy Link
					</button>
				</div>
			}
			isClosable={false}
		/>,

		// <ModalWithText
		// 	header={'Stay Updated with Our Socials!'}
		// 	description={
		// 		'Follow us on Twitter and Telegram to keep up with the latest news, updates, and special offers.'
		// 	}
		// 	extraElement={
		// 		<div className='social-link-popuped flex w-[28rem] flex-col items-center justify-center gap-6 md:text-2xl'>
		// 			<SocialLink
		// 				title={'Join on Telegram'}
		// 				subtitile={'@UndressLove'}
		// 				icon={
		// 					<svg
		// 						xmlns='http://www.w3.org/2000/svg'
		// 						width='49'
		// 						height='48'
		// 						viewBox='0 0 49 48'
		// 						fill='none'
		// 					>
		// 						<path
		// 							d='M11.5 26.6964L16.1443 39.7762C16.1443 39.7762 16.7249 41 17.3467 41C17.9684 41 27.2162 31.2109 27.2162 31.2109L37.5 11L11.6657 23.32L11.5 26.6964Z'
		// 							fill='#C8DAEA'
		// 						/>
		// 						<path
		// 							d='M18.3822 30L17.5152 39.6815C17.5152 39.6815 17.1523 42.6481 19.9751 39.6815C22.798 36.715 25.5 34.4275 25.5 34.4275'
		// 							fill='#A9C6D8'
		// 						/>
		// 						<path
		// 							d='M11.8262 27.401L2.34099 24.2789C2.34099 24.2789 1.2074 23.8143 1.57242 22.7607C1.64756 22.5434 1.79913 22.3586 2.25257 22.0409C4.35425 20.561 41.1528 7.19919 41.1528 7.19919C41.1528 7.19919 42.1918 6.84549 42.8046 7.08074C42.9562 7.12815 43.0926 7.21538 43.1999 7.33353C43.3072 7.45167 43.3815 7.59647 43.4151 7.75313C43.4814 8.02983 43.509 8.31451 43.4974 8.59894C43.4945 8.84499 43.465 9.07305 43.4427 9.43067C43.2185 13.0838 36.5116 40.3486 36.5116 40.3486C36.5116 40.3486 36.1103 41.944 34.6726 41.9986C34.3193 42.0101 33.9673 41.9497 33.6376 41.8208C33.3079 41.692 33.0072 41.4974 32.7536 41.2487C29.9322 38.797 20.1808 32.1765 18.026 30.7204C17.9774 30.687 17.9364 30.6434 17.9059 30.5926C17.8754 30.5418 17.8561 30.485 17.8491 30.426C17.819 30.2725 17.9842 30.0824 17.9842 30.0824C17.9842 30.0824 34.9641 14.835 35.4159 13.2343C35.4509 13.1103 35.3187 13.0492 35.1412 13.1035C34.0135 13.5226 14.4633 25.995 12.3056 27.3716C12.1502 27.419 11.9861 27.4291 11.8262 27.401Z'
		// 							fill='white'
		// 						/>
		// 					</svg>
		// 				}
		// 				link={'https://t.me/undresslove'}
		// 			/>
		// 		</div>
		// 	}
		// 	isClosable={false}
		// />,
	]
}
