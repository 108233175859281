import ModalWithText from './modal_simpleWithText'

const OneButtonModal = ({
	header,
	description,
	buttonText,
	buttonAction,
	isClosable = true,
}) => {
	return (
		<ModalWithText
			header={header}
			description={description}
			isClosable={isClosable}
			extraElement={
				<button
					className='button flex w-full justify-center gap-2 text-base md:text-2xl'
					onClick={() => buttonAction()}
				>
					{buttonText}
				</button>
			}
		/>
	)
}

export default OneButtonModal
