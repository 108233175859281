import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import PromptButton from '../promptButton'
import './modePicker.css'

export default function ModePicker({
	setSelectedType,
	setIsPromptLocked,
	userPlan,
	allPrompts,
	selectedMode,
	setSelectedMode,
	setShowModePicker,
}) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [subscribedTwitter, setSubscribedTwitter] = useState(
		// getUserInfo().subbedTwitter
		'test'
	)

	const [subscribedTelegram, setSubscribedTelegram] = useState(
		// getUserInfo().subbedTelegram
		'test'
	)
	const isAgeVerified = !!Cookies.get('birth')

	return (
		<>
			<h1 className='text-[22px] font-semibold'>Choose Type</h1>
			<div className='flex flex-col gap-[8px]'>
				<div className='flex w-full shrink-0 items-center justify-center gap-[10px] rounded-[20px] text-[20px] text-[#898989]'>
					{['LINGERIE', 'BIKINI'].map(
						(e, index) =>
							(e === 'BIKINI' || e === 'LINGERIE') && (
								<PromptButton
									onClick={(type) => setSelectedType(type)}
									navigate={navigate}
									userPlan={userPlan}
									setIsPromptLocked={setIsPromptLocked}
									isAgeVerified={isAgeVerified}
									promptName={e}
									key={index}
									selectedMode={selectedMode}
									setSelectedMode={setSelectedMode}
									setShowModePicker={setShowModePicker}
								/>
							)
					)}
				</div>

				<div className='promt-button flex w-full shrink-0 items-center justify-center gap-[10px] rounded-[20px] text-[20px] text-[#898989] '>
					{allPrompts.map(
						(element, index) =>
							(element == 'SEX' || element == 'BDSM') && (
								<PromptButton
									onClick={(type) => setSelectedType(type)}
									navigate={navigate}
									userPlan={userPlan}
									setIsPromptLocked={setIsPromptLocked}
									isAgeVerified={isAgeVerified}
									promptName={element}
									key={index}
									selectedMode={selectedMode}
									setSelectedMode={setSelectedMode}
									setShowModePicker={setShowModePicker}
								/>
							)
					)}
				</div>

				<div className='promt-button flex w-full shrink-0 items-center justify-center gap-[10px] rounded-[20px] text-[20px] text-[#898989] '>
					{allPrompts.map(
						(element, index) =>
							(element === 'UNDRESS' || element === 'SHIBARI') && (
								<PromptButton
									onClick={(type) => setSelectedType(type)}
									navigate={navigate}
									userPlan={userPlan}
									setIsPromptLocked={setIsPromptLocked}
									isAgeVerified={isAgeVerified}
									promptName={element}
									key={index}
									selectedMode={selectedMode}
									setSelectedMode={setSelectedMode}
									setShowModePicker={setShowModePicker}
								/>
							)
					)}
				</div>
			</div>
		</>
	)
}
