import { registerViaMail } from '~/apiNEW/user/AuthRequests'
import { EmailAuthSteps, RegisterViaMailParams } from '~/lib/types'
import {
	DOMAIN_PATTERN,
	IP_PATTERN,
	PASSWORD_REGEX,
	USER_PATTERN,
} from '~/lib/validation/regexPatterns'
import { OrangeButton } from '~/shared/ui/button/OrangeButton'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import Cookies from 'js-cookie'

interface SignUpProps {
	setActiveModal: (modal?: any) => void
	setActiveStep: (step?: EmailAuthSteps) => void
}

type Inputs = {
	email: string
	password: string
}

export const SignUp = ({ setActiveModal, setActiveStep }: SignUpProps) => {
	const [isLoading, setIsLoading] = React.useState(false)
	const anonymousId = Cookies.get('anonymousId')
	const referral = Cookies.get('referral')
	const subId = Cookies.get('subId')

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>()
	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		setIsLoading(true)
		try {
			const params: RegisterViaMailParams = {
				email: data.email.toLowerCase(),
				password: data.password,
			}

			if (anonymousId) {
				params.anonymous_task = anonymousId
			}

			if (referral) {
				params.ref_code = referral
			}

			if (subId) {
				params.keitaro_sub_id = subId
			}

			const result = await registerViaMail(params)
			if (result.need_confirmation === true) {
				setActiveStep('emailConfirmSignUp')
			} else if (result.token !== null) {
				Cookies.set('token', result.token, { expires: 60 })
				window.location.href = '/edit'
			} else {
				toast.error(
					'Something went wrong, please try again or select other authorization method'
				)
			}
		} catch (error: any) {
			toast.error(error.response.data)
		} finally {
			setIsLoading(false)
		}
	}

	const validateEmail = (email: string) => {
		if (!email.includes('@')) {
			return 'Please include an "@" in the email address. E.g., "username@example.com"'
		}
		const [localPart, domainPart] = email.split('@')
		if (!USER_PATTERN.test(localPart)) {
			return 'The username part of the email (before the "@") contains invalid characters or format.'
		}
		if (domainPart.startsWith('[')) {
			if (!IP_PATTERN.test(domainPart)) {
				return 'The domain part of the email is supposed to be an IP address but is incorrectly formatted. Correct format should be "[192.168.0.1]"'
			}
		} else {
			if (!DOMAIN_PATTERN.test(domainPart)) {
				return 'The domain part of the email (after the "@") contains invalid characters or format.'
			}
		}
		return true
	}

	return (
		<>
			<div className='flex items-start justify-between text-[24px] font-bold'>
				<button onClick={() => setActiveStep('emailSignIn')}>
					<img
						src='/icons/chevron.svg'
						alt='chevron'
						className='min-w-[20px] pt-1 opacity-40 transition-all hover:opacity-60'
						width={24}
						height={24}
					/>
				</button>
				Sign up
				<button onClick={() => setActiveModal(undefined)}>
					<img
						src='/icons/close-alt.svg'
						alt='close'
						className='min-w-[20px] pt-1 opacity-40 transition-all hover:opacity-60'
						width={24}
						height={24}
					/>
				</button>
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				id='form-signup'
				className='flex flex-col gap-[16px] pt-[24px]'
			>
				<input
					disabled={isLoading}
					type='email'
					aria-required='true'
					autoCapitalize='false'
					spellCheck='false'
					placeholder='Email'
					{...register('email', {
						required: 'Email is required',
						validate: validateEmail,
					})}
					className={`min-h-[48px] rounded-[12px] border border-[#1c1c1c] bg-[#1c1c1c] px-[16px] outline-none transition-all focus:border-orange ${
						isLoading && 'opacity-80'
					}`}
				/>
				{errors.email && (
					<span className='pl-[16px] text-xs text-red-500'>
						{errors.email.message}
					</span>
				)}
				<input
					disabled={isLoading}
					type='password'
					placeholder='Password'
					{...register('password', {
						required: 'Password is required',
						minLength: {
							value: 8,
							message: 'Password must have at least 8 characters',
						},
						maxLength: {
							value: 128,
							message: 'Password must not exceed 128 characters',
						},
						pattern: {
							value: PASSWORD_REGEX,
							message: 'Password must include only valid characters',
						},
					})}
					className={`min-h-[48px] rounded-[12px] border border-[#1c1c1c] bg-[#1c1c1c] px-[16px] outline-none transition-all focus:border-orange ${
						isLoading && 'opacity-80'
					}`}
				/>
				{errors.password && (
					<span className='pl-[16px] text-xs text-red-500'>
						{errors.password.message}
					</span>
				)}
				<OrangeButton
					className='mt-[8px] flex justify-center disabled:bg-[#1d1d1d]'
					onClick={() => handleSubmit}
					disabled={isLoading}
				>
					{!isLoading ? (
						'Sign up'
					) : (
						<img
							src='/icons/spinner.svg'
							alt='spinner'
							className='animate-spin justify-center'
							height={24}
							width={24}
						/>
					)}
				</OrangeButton>
				<p
					className={`my-[16px] text-center transition-all ${
						isLoading && 'opacity-60'
					}`}
				>
					Have an account?{' '}
					<button
						disabled={isLoading}
						onClick={() => setActiveStep('emailSignIn')}
						className='text-orange underline transition-all hover:opacity-60 disabled:hover:opacity-100'
					>
						Sign in
					</button>
				</p>
			</form>
		</>
	)
}
