import React from 'react'
import Image from 'next/image'

interface MethodIconsPackProps {
	iconsData: { logo_urls?: []; total_count?: number }
	isSelected: boolean
}

export default function MethodIconsPack({
	iconsData,
	isSelected,
}: MethodIconsPackProps) {
	return (
		<div className='flex items-center'>
			{iconsData.logo_urls &&
				iconsData.logo_urls.map((url, index) => {
					const style = {
						marginLeft: index === 0 ? '0' : '-10px',
						zIndex: index,
					}

					return (
						<img
							key={index}
							src={url}
							alt='bank logo'
							className={`rounded-full  ${
								isSelected
									? 'border border-[#7d4218]'
									: 'border border-[#1d1d1d]'
							}`}
							style={style}
							width={24}
							height={24}
						/>
					)
				})}

			{iconsData.total_count && iconsData.total_count > 0 ? (
				<span
					className={`z-[16] ml-[-10px] flex min-h-[24px] min-w-[24px] items-center justify-center rounded-full ${
						isSelected
							? 'border border-[#7d4218] bg-[#7d4218]'
							: 'border border-[#1d1d1d] bg-[#383838]'
					} text-[10px]`}
				>
					+{iconsData.total_count}
				</span>
			) : null}
		</div>
	)
}
