type IconName = 'mastercard' | 'visa'

interface PaymentMethodConfig {
	[key: string]: {
		icon?: string
		discount?: string
		title?: string
		rightSideIcons?: IconName[]
	}
}

export const PaymentMethodConfig: PaymentMethodConfig = {
	CRYPTOCURRENCY: {
		icon: '/icons/providers/crypto.svg',
		// discount: '-20%',
	},
	BANK_CARD: {
		icon: '/icons/providers/card.svg',

		rightSideIcons: ['mastercard', 'visa'],
	},
	BANK_CARD_VISA: {
		icon: '/icons/providers/card.svg',
		rightSideIcons: ['visa'],
	},
	BANK_CARD_MASTERCARD: {
		icon: '/icons/providers/card.svg',
		rightSideIcons: ['mastercard'],
	},
	TELEGRAM_STARS: {
		icon: '/icons/telegram.svg',
	},
	WECHAT: { icon: '/icons/providers/wechat.svg' },
	ALIPAY: { icon: '/icons/providers/alipay.svg' },
	BANKS_OF_SINGAPORE: {},
	LOCAL_METHODS: {},
	PAYPAL: { icon: '/icons/providers/paypal.svg' },
	WISE: { icon: '/icons/providers/wise.svg' },
	REVOLUT: {
		icon: '/icons/providers/revolut.svg',
	},
	BANKS_OF_TURKEY: {},
	BANKS_OF_INDIA: {},
	BANKS_OF_KOREA: {},
	BANKS_OF_PAKISTAN: {},
	BANGLADESH_BCASH: { icon: '/icons/providers/bangladesh_bcash.svg' },
	EGYPT_MOBILE: { icon: '/icons/providers/egypt_mobile.svg' },
	RUSSIA_SBP: { icon: '/icons/providers/sbp_bank.svg' },
	RUSSIA_BANK_CARD: {},
	NODA_LIVE: {},
}
