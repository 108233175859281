import { requestNewGeneration } from '~/api/user/UserInfoRequestPacket'
import { validateDataOnReGenerateImage } from '~/lib/utils'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function Result({
	downloadImage,
	originalImage,
	sendImageGenerationRequest,
	setShowTips,
	userInfo,
	isNewFileRequested,
	setNewFileRequested,
}) {
	const navigate = useNavigate()
	const { t } = useTranslation()

	useEffect(() => {
		if (isNewFileRequested && localStorage.getItem('originalImage') != null) {
			sessionStorage.removeItem('maskImageStorage')
			sessionStorage.removeItem('maskIdStorage')
			sessionStorage.removeItem('generateAgain')
			sessionStorage.removeItem('originalIdStorage')
			sessionStorage.removeItem('resolutionStorage')
			sessionStorage.removeItem('bodyTypeStorage')
			sessionStorage.removeItem('ageStorage')
			sessionStorage.removeItem('selectedModeStorage')
			sessionStorage.removeItem('isTrialPopupShowed')
			localStorage.removeItem('maskSavedImage')
			requestNewGeneration()
				.then(() => {
					navigate(0)
				})
				.catch(() => {
					navigate(0)
				})
		}
	}, [originalImage])
	return (
		<>
			<button
				className='button flex w-full justify-center text-[22px]'
				onClick={() => {
					downloadImage()
				}}
			>
				{t('download_resut')}
			</button>

			<button
				className='button flex w-full justify-center text-[22px]'
				onClick={() => {
					const uploadAction = () => {
						if (originalImage) {
							localStorage.removeItem('originalImage')
							sessionStorage.removeItem('maskImageStorage')
							sessionStorage.removeItem('maskIdStorage')
							sessionStorage.removeItem('generateAgain')
							sessionStorage.removeItem('originalIdStorage')
							sessionStorage.removeItem('resolutionStorage')
							sessionStorage.removeItem('bodyTypeStorage')
							sessionStorage.removeItem('ageStorage')
							sessionStorage.removeItem('selectedModeStorage')
							sessionStorage.removeItem('isTrialPopupShowed')
							localStorage.removeItem('maskSavedImage')
							requestNewGeneration()
								.then(() => {
									navigate(0)
								})
								.catch(() => {
									navigate(0)
								})
						}
					}

					uploadAction()
				}}
			>
				{t('generate_another')}
			</button>
			<div className='flex w-full rounded-full bg-[#171717]'>
				<button
					className='h-[75px] flex-grow cursor-pointer rounded-full bg-orange font-semibold transition-all hover:scale-[98%]'
					onClick={() => {
						const anotherModeAction = () => {
							if (originalImage) {
								sessionStorage.removeItem('maskImageStorage')
								sessionStorage.removeItem('maskIdStorage')
								sessionStorage.removeItem('generateAgain')
								sessionStorage.removeItem('originalIdStorage')
								sessionStorage.removeItem('resolutionStorage')
								sessionStorage.removeItem('bodyTypeStorage')
								sessionStorage.removeItem('ageStorage')
								sessionStorage.removeItem('selectedModeStorage')
								sessionStorage.removeItem('isTrialPopupShowed')
								requestNewGeneration()
									.then(() => {
										navigate(0)
									})
									.catch(() => {
										navigate(0)
									})
							}
						}

						anotherModeAction()
					}}
				>
					<h5 className='h-auto grow px-4 text-center text-[22px] font-semibold'>
						Try another mode
					</h5>
				</button>
			</div>

			{/*Temporary disable Regenerate photo button*/}
			{false && validateDataOnReGenerateImage(userInfo) && (
				<button
					className='button flex w-full justify-center text-[22px]'
					onClick={() => {
						if (originalImage) {
							sessionStorage.setItem('generateAgain', 'true')
							requestNewGeneration()
								.then(() => {
									navigate(0)
								})
								.catch(() => {
									navigate(0)
								})
						}
					}}
				>
					Regenerate Photo
				</button>
			)}

			<div
				className='box-tool-ref box-tool-help hidden'
				onClick={() => {
					setShowTips(true)
				}}
			>
				<div className='tool-ref'></div>
				<p>Tips to make cool images</p>
			</div>
		</>
	)
}
