import { API_HOST } from '~/shared/api'
import axios from 'axios'
import Cookies from 'js-cookie'

export function imageGenerationRequest(
	imageData,
	maskData,
	traits,
	checked = false,
	userWarnedAboutForbiddenContent = false
) {
	return {
		maskUrl: '',
		originalUrl: '',
		resultUrl: '',
		tempUrl: '',
		originalId: imageData,
		maskId: maskData,
		traits: traits,
		resultChecked: checked,
		userWarnedAboutForbiddenContent: userWarnedAboutForbiddenContent,
	}
}

export async function stopImageGeneration() {
	return await axios.post(
		`${API_HOST}/api/v1/user/leaveQueue`,
		{},
		{
			headers: {
				Authorization: Cookies.get('token'),
			},
		}
	)
}

export async function sendImageGenerationRequest(
	imageData,
	maskData,
	traits,
	checked = false
) {
	const userWarnedAboutForbiddenContent =
		sessionStorage.getItem('userWarnedAboutForbiddenContent') === 'true'
	return await axios.post(
		`${API_HOST}/api/v1/image/generate`,
		JSON.stringify(
			imageGenerationRequest(
				imageData,
				maskData,
				traits,
				checked,
				userWarnedAboutForbiddenContent
			)
		),
		{
			headers: {
				Authorization: Cookies.get('token'),
			},
		}
	)
}
