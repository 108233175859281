import {
	createPaymentLink,
	getPaymentMethods,
} from '~/apiNEW/user/PaymentRequests'
import { PaymentMethodConfig } from '~/lib/configs/PaymentConfig'
import { PaymentMethod, SubPlanTypes } from '~/lib/types'
import { OrangeButton } from '~/shared/ui/button/OrangeButton'
import { Checkbox } from '~/shared/ui/checkbox'
import { ModalWindow } from '~/shared/ui/modal'
import React from 'react'
import toast from 'react-hot-toast'
import ExtraFieldsForm from './ExtraFieldsForm'
import MethodIconsPack from './MethodIconsPack'

interface PayMethodModalProps {
	selectedPlan: SubPlanTypes
	setActiveModal: (modal?: any) => void
}

export const PayMethodModal = ({
	selectedPlan,
	setActiveModal,
}: PayMethodModalProps) => {
	const [availableMethods, setAvailableMethods] = React.useState<
		PaymentMethod[] | undefined
	>(undefined)
	const [futurePaymentId, setFuturePaymentId] = React.useState<
		string | undefined
	>(undefined)
	const [selectedMethod, setSelectedMethod] = React.useState<
		PaymentMethod | undefined
	>(undefined)

	const [extraFields, setExtraFields] = React.useState(undefined)
	const [extraFieldsModal, setExtraFieldsModal] = React.useState<boolean>(false)

	const [isLoading, setIsLoading] = React.useState<boolean>(false)
	const [isAgreed, setIsAgreed] = React.useState(false)

	const simplifyPlanName = (planName: string) => {
		return planName.replace(/(_\d+m)?_u_love$/, '').replace(/_\d+m$/, '')
	}

	React.useEffect(() => {
		const fetchPaymentsMethods = async () => {
			const simplifiedPlan = simplifyPlanName(selectedPlan)

			setIsLoading(true)
			try {
				const paymentMethodsResponse = await getPaymentMethods(simplifiedPlan)
				paymentMethodsResponse.reverse()

				paymentMethodsResponse.forEach((paymentOption) => {
					if (paymentOption.product_id === selectedPlan) {
						setAvailableMethods(paymentOption.payment_methods)
						setFuturePaymentId(paymentOption.future_payment_id)
					}
				})
			} catch (error) {
				toast.error('Something went wrong. Please try again.')
			} finally {
				setIsLoading(false)
			}
		}
		fetchPaymentsMethods()
	}, [selectedPlan])

	const handlePayment = async () => {
		if (extraFields) {
			setExtraFieldsModal(true)
		} else {
			try {
				setIsLoading(true)
				if (selectedMethod && futurePaymentId) {
					const paymentUrl = await createPaymentLink(
						futurePaymentId,
						selectedMethod.id
					)
					if (paymentUrl) {
						const a = document.createElement('a')
						a.href = paymentUrl
						a.rel = 'noreferrer'
						a.style.display = 'none'
						document.body.appendChild(a)
						a.click()
						document.body.removeChild(a)
					}
				}
				setIsLoading(false)
			} catch (error) {
				toast.error('Reload the page and try again.')
				setIsLoading(false)
			}
		}
	}

	return (
		<ModalWindow width='400px' setActiveModal={setActiveModal}>
			{extraFieldsModal && extraFields ? (
				<ExtraFieldsForm
					extraFields={extraFields}
					closeModal={setActiveModal}
					setExtraFieldsModal={setExtraFieldsModal}
					selectedMethod={selectedMethod}
					futurePaymentId={futurePaymentId}
				/>
			) : (
				<>
					<div className='flex items-start justify-between text-[24px] font-bold'>
						Choose a payment method
						<button onClick={() => setActiveModal(undefined)}>
							<img
								src='/icons/close.svg'
								alt='close'
								className='pt-2 opacity-40 transition-all hover:opacity-60'
							/>
						</button>
					</div>
					<div className='flex flex-col gap-[8px]'>
						{availableMethods === undefined ? (
							<div className='flex w-full justify-center pt-[16px]'>
								<img
									src='/icons/spinner.svg'
									alt='spinner'
									className='animate-spin'
									height='32'
									width='32'
								/>
							</div>
						) : availableMethods.length === 0 ? (
							<p className='text-center'>
								Payments are temporarily unavailable. We`re working on it.
							</p>
						) : (
							<div className='flex flex-col'>
								{isLoading ? (
									<div className='flex w-full justify-center py-[32px]'>
										<img
											src='/icons/spinner.svg'
											alt='spinner'
											className='animate-spin justify-center'
											height='24'
											width='24'
										/>
									</div>
								) : (
									<div className='flex flex-col gap-[16px] pt-[24px]'>
										{/* <p className='text-[18px] font-bold'>Method</p> */}
										<div className='flex flex-col gap-[8px]'>
											{availableMethods.map((method) => {
												const config = PaymentMethodConfig[method.type]
												if (!config) return null
												const isSelected = selectedMethod?.id === method.id

												return (
													<button
														key={method.id}
														disabled={isSelected}
														onClick={() => {
															if (method.extra_fields) {
																setExtraFields(method.extra_fields)
															} else setExtraFields(undefined)
															setSelectedMethod(method)
														}}
														className={`flex items-center justify-between gap-[12px] rounded-full border bg-[#1d1d1d] px-[12px] py-[16px] text-[16px] font-bold transition-all ${
															isSelected
																? 'border-orange bg-[#2f2f2f] bg-orange/[12%]'
																: 'border-[#1d1d1d] bg-[#1d1d1d] hover:bg-[#2f2f2f]'
														}`}
													>
														<div className='flex items-center gap-[12px] text-left'>
															<img
																src={
																	config.icon
																		? config.icon
																		: '/icons/providers/default_bank.svg'
																}
																alt={method.type}
																width={24}
																height={24}
																className='max-h-[24px] max-w-[24px] text-left'
															/>
															{config.title ? config.title : method.title}
														</div>
														<div className='flex items-center gap-[12px]'>
															{config.discount && (
																<span
																	className={`rounded-full ${
																		isSelected
																			? 'bg-orange/[36%]'
																			: 'bg-[#393939]'
																	} px-[8px] py-[4px] text-[13px] transition-all`}
																>
																	{config.discount}
																</span>
															)}
															{config.rightSideIcons && (
																<>
																	{config.rightSideIcons.includes(
																		'mastercard'
																	) && (
																		<img
																			src='/icons/providers/mastercard-alt.svg'
																			alt='Mastercard'
																		/>
																	)}
																	{config.rightSideIcons.includes('visa') && (
																		<img
																			src='/icons/providers/visa.svg'
																			alt='Visa'
																		/>
																	)}
																</>
															)}
															{method.additional_params?.logo_urls && (
																<MethodIconsPack
																	iconsData={method.additional_params}
																	isSelected={isSelected}
																/>
															)}
														</div>
													</button>
												)
											})}
										</div>
									</div>
								)}
							</div>
						)}
						{!isLoading && (
							<>
								{selectedMethod?.need_tos && (
									<div className='flex justify-center gap-[12px] pt-[24px]'>
										<Checkbox
											isChecked={isAgreed}
											onClick={() => setIsAgreed(!isAgreed)}
										/>
										<p>
											I accept{` `}
											<a
												href='/terms'
												target='_blank'
												className='text-orange underline'
											>
												Terms of Service
											</a>
										</p>
									</div>
								)}
								<OrangeButton
									disabled={
										!selectedMethod ||
										isLoading ||
										(selectedMethod?.need_tos && !isAgreed)
									}
									className='mt-[24px] disabled:bg-[#1c1c1c]'
									onClick={handlePayment}
								>
									{extraFields ? 'Continue' : 'Pay'}
								</OrangeButton>
								{selectedMethod?.additional_params?.alert_instax && (
									<div className='flex justify-center gap-[12px] pt-[24px] text-center text-[9px] opacity-60'>
										<p>
											You will be redirected to a third party{` `}
											<a
												href='https://instaxchange.com'
												target='_blank'
												className='text-orange underline'
											>
												Instaxchange.com
											</a>
											, to purchase cryptocurrency with your card which can be
											used to complete your purchase. Instaxchange is a third
											party and has no relations to our name.
										</p>
									</div>
								)}
							</>
						)}
					</div>
				</>
			)}
		</ModalWindow>
	)
}
