const Loader = () => {
	return (
		<div className='box-loader'>
			<div className='loader'>
				<div className='em1-loader'>
					<img className='c-loader' src='/loader/cl.svg' alt='loader icon' />
					<img className='d-loader' src='/loader/dl.svg' alt='loader icon' />
				</div>
				<div className='em2-loader'>
					<img className='c-loader' src='/loader/cl.svg' alt='loader icon' />
					<img className='d-loader' src='/loader/dl.svg' alt='loader icon' />
				</div>
				<div className='em3-loader'>
					<img className='c-loader' src='/loader/cl.svg' alt='loader icon' />
					<img className='d-loader' src='/loader/dl.svg' alt='loader icon' />
				</div>
				<div className='em4-loader'>
					<img className='c-loader' src='/loader/cl.svg' alt='loader icon' />
					<img className='d-loader' src='/loader/dl.svg' alt='loader icon' />
				</div>
			</div>
		</div>
	)
}

export default Loader
