import { getWebsiteName } from '~/lib/utils'
import { useState } from 'react'

const ModalReferral = ({ setHideRefs, userInfo }) => {
	const [copied, setCopied] = useState(false)
	return (
		<>
			<div className='fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center p-0 transition-all animate-in fade-in-5 slide-in-from-top-8 duration-500 md:p-3'>
				<div className='absolute bottom-0 w-full rounded-t-xl bg-[#171717] p-8 shadow-lg md:static md:w-auto md:rounded-xl'>
					<h2 className='mb-4 w-full text-center text-[22px] font-semibold text-white'>
						Invite friends to earn credits!
					</h2>
					<h3 className='mb-4 w-full text-center text-[20px] font-semibold text-[#8b8b8b]'>
						<span className='text-[#e0a100]'>1 </span> invitation =
						<span className='text-[#e0a100]'> 2 </span> credit
					</h3>
					<div className='flex flex-col justify-center gap-3 pt-3'>
						<div className='flex flex-col gap-5 rounded-lg bg-[#292929] p-5 text-[22px]'>
							<div className='flex justify-between'>
								Invited:
								<span className='text-[#e0a100]'>{userInfo.refsInvited}</span>
							</div>
						</div>
						<div className='relative flex w-full items-center pt-8'>
							<div className='flex w-full flex-col gap-2'>
								<input
									readOnly='readonly'
									className={`w-full rounded-full border-2 bg-transparent px-6 py-4 font-bold outline-none transition-all ${
										copied
											? ' border-orange text-center text-[22px] text-white'
											: 'border-[#2e2e2e] text-[22px] text-[#898989] hover:border-[#393939] active:scale-95'
									} transition-all active:border-orange`}
									value={
										copied
											? 'Copied!'
											: `https://${getWebsiteName()}/ref/` + userInfo.inviteCode
									}
									onClick={() => {
										const link = `https://${getWebsiteName()}/ref/${
											userInfo.inviteCode
										}`

										window.dataLayer = window.dataLayer || []
										window.dataLayer.push({
											event: 'aevent',
											event_name: 'copy_ref',
										})

										if (window.umami) {
											window.umami.track('Copy ref')
										}

										navigator.clipboard.writeText(link)
										setCopied(true)
										setTimeout(() => {
											setCopied(false)
										}, 1500)
									}}
								/>
							</div>
						</div>
						<button
							onClick={() => {
								setHideRefs(true)
							}}
							className='rounded-full bg-orange py-4 text-[20px] font-semibold text-white transition-all hover:bg-[#ff9330]'
						>
							Close
						</button>
					</div>
				</div>
			</div>
			<div className='fixed left-0 top-0 z-10 h-full w-full bg-black opacity-60 animate-in fade-in-5 duration-500'></div>
		</>
	)
}
export default ModalReferral
