import terms from '~/lib/data/termsData'
import { getWebsiteNameCapitalized } from '~/lib/utils'
import { Footer } from '~/shared/ui/footer'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Link } from 'react-router-dom'

export default function Refunds() {
	return (
		<HelmetProvider>
			<div className='flex min-h-screen flex-col bg-black'>
				<Helmet>
					<title>Refunds Policy</title>
				</Helmet>
				<div className='flex flex-grow flex-col items-center gap-[20px]'>
					<div className='flex w-full items-center justify-center gap-[40px] p-4 pt-[50px] llg:pt-[100px]'>
						<div className='flex w-full max-w-[1110px] flex-col items-center justify-between gap-[40px]'>
							<div className='flex w-full items-center justify-between'>
								<Link to='/edit'>
									<h4 className='text-[28px] font-medium'>
										{getWebsiteNameCapitalized()}
									</h4>
								</Link>
								<Link to='/edit'>
									<img src='logo.svg' className='h-auto w-[45px]' alt='logo' />
								</Link>
							</div>
							<div className='flex w-full flex-col justify-start gap-8'>
								<h5 className='text-[28px] font-semibold'>Refunds Policy</h5>
								<div className='flex flex-col gap-4 text-lg'>
									<p>
										In the event you have concerns about a transaction, we
										strongly recommend contacting our support team before
										initiating a refund. Herein, we lay out our policies
										concerning refunds initiated by our customers.
									</p>
									<p>
										If you believe an error has occurred in your transaction, we
										kindly request that you contact us at support@undress.app to
										discuss any concerns before pursuing a refund through your
										bank or payment provider. This allows us an opportunity to
										address your concerns directly and provide a resolution that
										may negate the need for a refund.
									</p>
									<p>
										<b>Refund Process:</b>
										<br />
										1. Refund requests must be made within 14 days from the
										purchase date.
										<br />
										2. Once a refund is approved, the processing may take up to
										10 business days, depending on bank conditions.
										<br />
										3. Refunds are typically issued via the initial payment
										method. If refunding to the original method is not possible,
										we will seek an alternative means to return the funds.
									</p>
									<p>
										We understand that occasionally users may wish to reconsider
										their purchases. If you find yourself unsatisfied with a
										purchase, you may be eligible for a refund. For all
										refund-related inquiries or to initiate a request, please
										reach out to our dedicated support team at{' '}
										<a
											href='mailto:support@undress.love'
											className='text-orange'
										>
											support@undress.love
										</a>
										.
									</p>
									<p>
										<b>Last updated: 15 August 2024</b>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='flex justify-center p-[30px]'>
					<Link
						to='/edit'
						onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
						className='p-4 text-[22px] font-medium text-white/50 transition-all hover:text-white/30'
					>
						Back to edit
					</Link>
				</div>
				<div className='p-[30px]'>
					<Footer />
				</div>
			</div>
		</HelmetProvider>
	)
}
