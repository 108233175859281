import React from 'react'
import SimpleModal from './modal_simple'

const ModalWithText = ({
	header,
	description,
	extraElement,
	isClosable = true,
	extraCloseAction,
}) => {
	return (
		<SimpleModal
			isClosable={isClosable}
			extraCloseAction={extraCloseAction}
			additionElement={
				<div
					className={`w-[385px] ${
						isClosable ? 'max-w-[95%]' : 'max-w-full'
					} inline-flex h-auto flex-col items-center justify-center gap-32 rounded-[20px] bg-neutral-900 p-7`}
				>
					<div className='flex h-[170px] flex-col items-start justify-start gap-[20px] self-stretch'>
						<div className='self-stretch text-center text-[20px] text-white md:text-[28px]'>
							{header}
						</div>
						<div className='h-[0px] self-stretch border-2 border-white'></div>
						<div className='flex h-auto justify-center gap-2 self-stretch text-center text-[18px] text-zinc-500 md:text-[20px]'>
							{description}
						</div>
					</div>
					{extraElement}
				</div>
			}
		/>
	)
}

export default ModalWithText
