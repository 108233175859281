import { EmailAuthSteps } from '~/lib/types'
import { ModalWindow } from '~/shared/ui/modal'
import { useState } from 'react'
import { ConfirmSignUp } from './ConfirmSignUp'
import { SignIn } from './SignIn'
import { SignUp } from './SignUp'

interface EmailAuthProps {
	setActiveModal: (modal?: any) => void
}

export const EmailAuth = ({ setActiveModal }: EmailAuthProps) => {
	const [activeStep, setActiveStep] = useState<EmailAuthSteps>('emailSignIn')

	return (
		<>
			<ModalWindow setActiveModal={setActiveModal}>
				{activeStep === 'emailSignIn' && (
					<SignIn
						setActiveStep={setActiveStep as (step?: EmailAuthSteps) => void}
						setActiveModal={setActiveModal}
					/>
				)}
				{activeStep === 'emailSignUp' && (
					<SignUp
						setActiveStep={setActiveStep as (step?: EmailAuthSteps) => void}
						setActiveModal={setActiveModal}
					/>
				)}
				{activeStep === 'emailConfirmSignUp' && (
					<ConfirmSignUp setActiveModal={setActiveModal} />
				)}
			</ModalWindow>
		</>
	)
}
