import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import XHR from 'i18next-http-backend'

// <---- add this

i18n
	.use(XHR)
	.use(Backend)
	// Enable automatic language detection
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		// resources,
		lng: 'en', // TODO: temporary override to EN
		fallbackLng: 'en',
		detection: {
			order: ['querystring', 'navigator'],
			cache: ['cookie'],
			lookupQuerystring: 'lng',
		},
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	})

export default i18n
