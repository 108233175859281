import { sendImageGenerationRequest } from '~/api/image/ImageGenerationRequest'
import React from 'react'
import { useEffect, useRef } from 'react'

const InappropriatePhotoWarning = ({
	setIsWariningVisible,
	navigate,
	originalImage,
}) => {
	const modalRef = useRef()

	useEffect(() => {
		document.body.style.overflow = 'hidden'

		return () => {
			document.body.style.overflow = 'auto'
		}
	}, [])

	function regenerateImageWithIgnoreChildCheck() {
		sessionStorage.setItem('generateAgain', 'true')
		sendImageGenerationRequest(undefined, undefined, undefined, true)
			.then(() => {
				sessionStorage.setItem('userWarnedAboutForbiddenContent', 'true')
				navigate(0)
			})
			.catch(() => {
				navigate(0)
			})
	}

	return (
		<>
			<div
				className='fixed left-0 top-0 z-[1000] flex h-full w-full items-center justify-center p-0 transition-all animate-in fade-in-5 slide-in-from-top-8 duration-500 md:p-3'
				ref={modalRef}
			>
				<div className='z-[1000] flex w-full max-w-[500px] flex-col items-center justify-between bg-[#171717] px-5 py-12 pt-8 md:rounded-[16px] md:px-12'>
					<h3 className='mb-[16px] text-[32px] text-orange'>Under 18</h3>

					<h2 className='flex justify-center text-center text-[18px] font-semibold text-white'>
						This website has detected possible deviation and probably your photo
						is not compliant with the rules of the website and applicable law,
						in case you confirm the photo meets all requirements of the Terms of
						Service and applicable law and You agree to take all responsibility,
						please proceed
					</h2>

					<div className='flex w-full flex-col justify-center gap-3 pt-6'>
						<button
							className='button flex w-full justify-center text-[22px]'
							onClick={() => {
								if (originalImage) {
									sessionStorage.removeItem('maskImageStorage')
									sessionStorage.removeItem('maskIdStorage')
									sessionStorage.removeItem('generateAgain')
									sessionStorage.removeItem('originalIdStorage')
									sessionStorage.removeItem('resolutionStorage')
									sessionStorage.removeItem('bodyTypeStorage')
									sessionStorage.removeItem('ageStorage')
									sessionStorage.removeItem('selectedModeStorage')
									localStorage.removeItem('originalImage')
									localStorage.removeItem('maskSavedImage')

									sendImageGenerationRequest(
										undefined,
										undefined,
										undefined,
										true
									)
										.then(() => {
											navigate(0)
										})
										.catch(() => {
											navigate(0)
										})
								}
								setIsWariningVisible(false)
							}}
						>
							Upload New Photo
						</button>

						{/*<button*/}
						{/*    className='button grey flex w-full justify-center text-[22px]'*/}
						{/*    onClick={() => { setIsWariningVisible(false); regenerateImageWithIgnoreChildCheck(); setIsWariningVisible(false) }}*/}
						{/*>*/}
						{/*    Process Image Anyway*/}
						{/*</button>*/}
					</div>
				</div>
			</div>
			<div className='z- duration-400 fixed left-0 top-0 z-10 h-full w-full bg-[#202020]/90 animate-in fade-in-5'></div>
		</>
	)
}

export default InappropriatePhotoWarning
