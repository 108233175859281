import { sendUserInfoRequest } from '~/api/user/UserInfoRequestPacket'
import { getWebsiteNameCapitalized } from '~/lib/utils'
import { Footer } from '~/shared/ui/footer'
import { useEffect, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import DiscordLoginButton from './auth/DiscordLoginButton'
import { EmailLoginButton } from './auth/EmailLoginButton'
import { GoogleLoginButton } from './auth/GoogleLoginButton'
import Loader from './loader'

export const General = () => {
	const { isDemo } = useSelector((state: any) => state.autoMaskReducer)

	const navigate = useNavigate()
	const { t } = useTranslation()

	const params = new URLSearchParams(window.location.search)
	const utmParameters = [
		'utm_source',
		'utm_medium',
		'utm_campaign',
		'utm_content',
		'utm_term',
	]

	for (let param of utmParameters) {
		let value = params.get(param)
		if (value) {
			Cookies.set(param, value)
		}
	}

	const [authorized, setAuthorized] = useState(false)

	useEffect(() => {
		const checkAuthorization = async () => {
			if (Cookies.get('token') !== undefined) {
				await sendUserInfoRequest()
					.then(() => {
						setAuthorized(true)
					})
					.catch(() => {
						setAuthorized(false)
					})
			} else {
				setAuthorized(false)
			}
		}

		checkAuthorization()
	}, [])

	useEffect(() => {
		const subId = params.get('subid')

		if (subId) {
			Cookies.set('subId', subId, { expires: 60 })
			params.delete('subId')
			window.history.replaceState({}, '', window.location.pathname)
		}
	}, [])

	return (
		<HelmetProvider>
			<div className='flex flex-col gap-[2rem] p-[16px] mmd:gap-[2rem] mmd:p-[24px]'>
				<div className='flex flex-col gap-[40px] mmd:flex-row mmd:gap-[30px]'>
					<div className='flex flex-col gap-[24px] mmd:w-full'>
						<div className='flex items-center gap-[0.5rem] self-stretch text-[1.5rem] mmd:hidden'>
							<div className='flex h-[4rem] flex-col items-center justify-center gap-[0.25rem] rounded-[0.75rem] p-0'>
								<img className='h-[4rem]' src='logo_rounded.svg' />
							</div>
							{getWebsiteNameCapitalized()}
						</div>
						<div className='general-bg relative flex h-[calc(100vh-550px)] shrink-0 items-end justify-center self-stretch rounded-[20px] bg-[#161616] mmd:h-[calc(100vh-60px)]'>
							<Loader />
							<div className='general-banner-scan'>
								<div className='general-banner absolute bottom-0 flex h-[calc(100vh-550px)] w-full rounded-[20px] mmd:h-[calc(100vh-60px)]' />
							</div>
						</div>
						<div className='flex flex-col gap-[32px] mmd:hidden'>
							{authorized || isDemo ? (
								<button
									className='flex justify-center rounded-full bg-gradient-to-br from-[#ff8c00] via-[#ff7801] to-[#ff3915] px-2 py-5 text-[24px] font-medium transition-all active:scale-[97%]'
									onClick={() => {
										navigate('/edit')
									}}
								>
									{t('launch')}
								</button>
							) : (
								<>
									<div className='flex w-full flex-col items-center gap-[16px]'>
										<GoogleLoginButton />
										{/* <DiscordLoginButton /> */}
										<EmailLoginButton />
									</div>
								</>
							)}
							<div className='flex flex-col gap-[16px] text-[18x]'>
								<div>
									{' '}
									Experience a cutting-edge{' '}
									<span className='mb-[10px] mt-[25px] inline bg-gradient-to-br from-[#ff8c00] via-[#ff7801] to-[#ff3915] bg-clip-text text-transparent'>
										AI tool
									</span>{' '}
									that creates striking nude and bikini images for free. Enhance
									the precision and realism of AI-generated photos with
									Undress.love's platform.{` `}
								</div>
								<div>
									Effortlessly produce authentic{' '}
									<span className='mb-[10px] mt-[25px] inline bg-gradient-to-br from-[#ff8c00] via-[#ff7801] to-[#ff3915] bg-clip-text text-transparent'>
										undressed AI
									</span>{' '}
									images with a click. Explore the convenience and accuracy of
									this advanced tool to create lifelike unclad photos online.
								</div>
							</div>
						</div>
					</div>
					<div className='hidden flex-col justify-between gap-[28px] mmd:flex'>
						<div className='flex flex-col gap-[15px]'>
							<div className='flex items-center gap-[0.5rem] self-stretch text-[1.5rem]'>
								<div className='flex h-[4rem] flex-col items-center justify-center gap-[0.25rem] rounded-[0.75rem] p-0'>
									<img className='h-[4rem]' src='logo_rounded.svg' />
								</div>
								{getWebsiteNameCapitalized()}
							</div>
							{authorized || isDemo ? (
								<button
									className='flex h-[4rem] items-center justify-center rounded-full bg-gradient-to-br from-[#ff8c00] via-[#ff7801] to-[#ff3915] px-2 py-5 text-[24px] font-medium transition-all active:scale-[97%]'
									onClick={() => {
										navigate('/edit')
									}}
								>
									{t('launch')}
								</button>
							) : (
								<>
									<div className='flex w-full flex-col items-center gap-[16px]'>
										<GoogleLoginButton />
										{/* <DiscordLoginButton /> */}
										<EmailLoginButton />
									</div>
								</>
							)}
						</div>
						<div className='flex flex-col gap-[16px] mmd:flex'>
							<div className='flex w-[420px] flex-col gap-[16px] text-[18px]'>
								<div>
									{' '}
									Experience a cutting-edge{' '}
									<span className='mb-[10px] mt-[25px] inline bg-gradient-to-br from-[#ff8c00] via-[#ff7801] to-[#ff3915] bg-clip-text text-transparent'>
										AI tool
									</span>{' '}
									that creates striking nude and bikini images for free. Enhance
									the precision and realism of AI-generated photos with
									Undress.love's platform.{` `}
								</div>
								<div>
									Effortlessly produce authentic{' '}
									<span className='mb-[10px] mt-[25px] inline bg-gradient-to-br from-[#ff8c00] via-[#ff7801] to-[#ff3915] bg-clip-text text-transparent'>
										undressed AI
									</span>{' '}
									images with a click. Explore the convenience and accuracy of
									this advanced tool to create lifelike unclad photos online.
								</div>
							</div>
							{/* <a
								target='_blank'
								href='https://t.me/undresslove'
								className='hidden rounded-[20px] bg-[#161616] p-[16px] px-[20px] transition-all hover:bg-[#535353] mmd:flex'
							>
								<div className='flex gap-[20px]'>
									<img
										src='/icons/telegramLogo.svg'
										alt='telegram logo'
										className='h-[48px] w-[48px]'
									/>
									<div className='flex flex-col gap-1 text-sm text-white'>
										Telegram
										<span className='text-[22px]'>@UndressLove</span>
									</div>
								</div>
							</a> */}
						</div>
					</div>
				</div>
				{/* <a
					target='_blank'
					href='https://t.me/undresslove'
					className='flex rounded-[20px] bg-[#161616] p-[20px] transition-all hover:bg-[#535353] mmd:hidden'
				>
					<div className='flex gap-[20px]'>
						<img
							src='/icons/telegramLogo.svg'
							alt='telegram logo'
							className='h-[48px] w-[48px]'
						/>
						<div className='flex flex-col gap-1 text-sm text-white'>
							Telegram
							<span className='text-[22px]'>@UndressLove</span>
						</div>
					</div>
				</a> */}
				<div>
					<h3 className='mb-[12px] text-left text-[20px] mmd:mb-[0] mmd:text-center'>
						{getWebsiteNameCapitalized()}
					</h3>
					<Footer />
				</div>
			</div>
		</HelmetProvider>
	)
}
