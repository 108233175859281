import {
	PaymentMethodsResponse,
	PaymentPricesResponse,
	Product,
} from '~/lib/types'
import { api } from '~/shared/api'

export async function getPaymentMethods(
	selectedPlan: string,
	gaClientId?: string | null
): Promise<PaymentMethodsResponse[]> {
	let url = `/payments/methods?productGroupId=${selectedPlan}`
	if (typeof gaClientId === 'string') {
		url += `&gaClientId=${gaClientId}`
	}

	const response = await api.get<PaymentMethodsResponse[]>(url)

	return response.data
}

export async function getPrices(): Promise<Product[]> {
	const response = await api.get<PaymentPricesResponse>(`/payments/prices`)
	return response.data.products
}

export async function createPaymentLink(
	future_payment_id: string,
	payment_method_id: string,
	extra_fields?: {}
): Promise<string> {
	const response = await api.post('/payments/create', {
		future_payment_id,
		payment_method_id,
		...(extra_fields && { extra_fields }),
	})

	return response.data.redirect_url
}
