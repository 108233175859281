import {
	getPlanPrices,
	sendUserInfoRequest,
} from '~/api/user/UserInfoRequestPacket'
import AuthorizationContext from '~/lib/authorizationContext'
import { getWebsiteNameCapitalized } from '~/lib/utils'
import { Footer } from '~/shared/ui/footer'
import { useContext, useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { PayMethodModal } from '../modals/PayMethodModal'
import PlanCard from './planCard'

export const Plan = () => {
	const { setAuthorized } = useContext(AuthorizationContext)
	const [userInfo, setUserInfo] = useState(undefined)
	const navigate = useNavigate()
	const location = useLocation()
	const { t } = useTranslation()
	const [selectedPlan, setSelectedPlan] = useState(undefined)
	const [activeTab, setActiveTab] = useState('sub')
	const [period, setPeriod] = useState(12)
	const [plans, setPlans] = useState([])

	const periods = [
		{
			value: 12,
			discount: 55,
			specialColor: 'bg-[#E0A100] text-black',
			textColor: 'text-black',
		},
		{ value: 3, discount: 5 },
		{ value: 1, discount: null, specialColor: false },
	]

	useEffect(() => {
		const checkAuthorization = async () => {
			if (Cookies.get('token') !== undefined) {
				await sendUserInfoRequest()
					.then((res) => {
						setAuthorized(true)
						navigate('/plan')

						if (res) {
							setUserInfo(res.data)
						}
					})
					.catch((e) => {
						setAuthorized(false)
						Cookies.set('auth_redirect', '/plan')
					})
			} else {
				setAuthorized(false)
				Cookies.set('auth_redirect', '/plan')
			}
		}

		checkAuthorization()
	}, [])

	useEffect(() => {
		const fetchPrices = async () => {
			const response = await getPlanPrices()
			setPlans(response.data.products)
		}

		fetchPrices()
	}, [])

	useEffect(() => {
		const params = new URLSearchParams(location.search)
		const tab = params.get('tab')
		setActiveTab(tab === 'special' ? 'special' : 'sub')
	}, [location.search])

	return (
		<HelmetProvider>
			<div className='flex min-h-screen flex-col bg-black'>
				<Helmet>
					<title>Plans</title>
				</Helmet>
				<div className='min-h-screen'>
					<div className='flex flex-grow flex-col items-center gap-[20px]'>
						<div className='flex w-full items-center justify-center gap-[40px] p-4 pt-[50px] llg:pt-[100px]'>
							<div className='flex w-full max-w-[1110px] flex-col items-center justify-between gap-[40px]'>
								<div className='flex w-full items-center justify-between'>
									<Link to='/edit'>
										<h4 className='text-[28px] font-medium'>
											{getWebsiteNameCapitalized()}
										</h4>
									</Link>
									<Link to='/edit'>
										<img
											src='logo.svg'
											className='h-auto w-[45px]'
											alt='logo'
										/>
									</Link>
								</div>
								<div className='flex flex-col items-center justify-center '>
									<h1 className='text-gray-700 text-[32px] font-bold'>
										Choose your plan
									</h1>
								</div>
								<div className='flex h-16 w-full items-center justify-center gap-1 rounded-full bg-[#121212] md:max-w-xl'>
									{periods.map((periodObj, i) => (
										<button
											key={i}
											className={`text-custom-white relative flex h-full w-[8rem] flex-grow items-center justify-center rounded-full text-[14px] font-bold md:text-[16px] ${
												periodObj.value == 12
													? 'border-2 border-[#E0A100] text-white'
													: ''
											}  ${
												period === periodObj.value
													? periodObj.specialColor || 'bg-[#e56e1e]'
													: 'bg-[#121212] text-[#8b8b8b] md:hover:bg-[#2A2929]'
											}`}
											onClick={() => setPeriod(periodObj.value)}
										>
											<div>
												{periodObj.value}{' '}
												{periodObj.value === 1 ? 'month' : 'months'}
											</div>
											{periodObj.discount && (
												<div
													className={`absolute top-0 flex h-[20px] w-[86px] translate-y-[-50%] transform items-center justify-center rounded-full text-[14px] ${
														periodObj.value == 12
															? 'bg-[#E0A100] text-black'
															: 'bg-[#854CCD] text-[#fff]'
													}`}
												>
													{periodObj.discount}% off
												</div>
											)}
										</button>
									))}
								</div>

								{activeTab == 'sub' &&
									plans.length > 0 &&
									period &&
									userInfo && (
										<div className='scroll-snap-type-x-mandatory flex w-full overflow-x-auto md:justify-center'>
											<PlanCard
												gold={true}
												planName={'Pro'}
												allPlans={plans}
												period={period}
												userInfo={userInfo}
												credits={600}
												features={[
													t('excellent_quality'),
													t('no_watermark'),
													t('no_queue'),
												]}
												setSelectedPlan={setSelectedPlan}
											/>
											<PlanCard
												gold={false}
												planName={'Standard'}
												allPlans={plans}
												period={period}
												userInfo={userInfo}
												credits={90}
												features={[
													t('high_quality'),
													t('faster_generations'),
													t('no_queue'),
												]}
												setSelectedPlan={setSelectedPlan}
											/>
											<PlanCard
												gold={false}
												planName={'Basic'}
												allPlans={plans}
												period={period}
												userInfo={userInfo}
												credits={15}
												features={[
													t('medium_quality'),
													t('faster_generations'),
												]}
												setSelectedPlan={setSelectedPlan}
											/>
										</div>
									)}
							</div>
						</div>
					</div>
					<div className='flex justify-center p-[30px]'>
						<div className='flex w-full flex-col items-center gap-3'>
							<Link
								to='/edit'
								className='p-4 text-[16px] font-medium text-white/50 transition-all hover:text-white/30'
							>
								Back to edit
							</Link>
						</div>
					</div>
				</div>
				<div className='p-[30px]'>
					<Footer />
				</div>
			</div>
			{selectedPlan && (
				<PayMethodModal
					setActiveModal={setSelectedPlan}
					selectedPlan={selectedPlan}
				/>
			)}
		</HelmetProvider>
	)
}
