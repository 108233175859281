import { requestUploadNewFile } from '~/redux/fileUploaderReducer.js'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

export default function UploadNewImageButton({ setOriginalImage }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	return (
		<div className='flex w-full rounded-full bg-[#171717]'>
			<button
				className='h-[75px] flex-grow cursor-pointer rounded-full bg-orange font-semibold transition-all hover:scale-[98%]'
				onClick={() => {
					localStorage.removeItem('originalImage')
					localStorage.removeItem('maskSavedImage')
					sessionStorage.removeItem('maskImageStorage')
					setOriginalImage(null)
					dispatch(requestUploadNewFile(true))
				}}
			>
				<h5 className='h-auto grow px-4 text-center text-[22px] font-semibold'>
					Upload image
				</h5>
			</button>
		</div>
	)
}
