import React from 'react'

export default function Queue({ userInfo, UserPlan, navigate, genEta }: any) {
	const [initialEta, setInitialEta] = React.useState(genEta)
	const [eta, setEta] = React.useState(genEta)

	React.useEffect(() => {
		if (eta > 0) {
			const intervalId = setInterval(() => {
				setEta((prevEta: number) => prevEta - 1000)
			}, 1000)

			return () => clearInterval(intervalId)
		}
	}, [])

	const formatTimeLeft = (milliseconds: number) => {
		const totalSeconds = Math.floor(milliseconds / 1000)
		const minutes = Math.floor(totalSeconds / 60)
		const seconds = totalSeconds % 60
		return `${minutes < 10 ? '0' : ''}${minutes}:${
			seconds < 10 ? '0' : ''
		}${seconds}`
	}

	const elapsedTime = initialEta - eta
	const progressPercent = Math.min(100, (elapsedTime / initialEta) * 100)

	return (
		<>
			<div className='flex w-full flex-col items-center gap-[20px] rounded-[20px] bg-[#171717] p-[20px]'>
				<span className='text-center text-[22px]'>
					{userInfo.taskStatus?.queue_pos === 0
						? 'Starting'
						: userInfo.taskStatus?.queue_pos === 1
						? 'You are first in line'
						: `Your position in line is 3`}
				</span>

				<div className='mb-[10px] flex w-full'>
					<div
						className={
							'h-[6px] w-full overflow-hidden rounded-[10px] bg-black ' +
							(userInfo.plan !== UserPlan.NO
								? userInfo.plan === UserPlan.PRO
									? 'bg-[#9747FF]'
									: 'bg-[#E0A100]'
								: '')
						}
					>
						<div
							className={`h-[6px] rounded-[10px] bg-orange transition-all`}
							style={{ width: `${progressPercent}%` }}
						/>
					</div>
				</div>

				{eta > 1000 && (
					<p className='text-center text-[22px] font-semibold text-[#8b8b8b] '>
						Average waiting time is {formatTimeLeft(eta)}
					</p>
				)}
			</div>

			<button
				className='flex w-full items-center justify-center gap-1 rounded-full bg-[#9747ff] p-[20px] text-[22px] font-semibold transition-all hover:bg-[#a865ff]'
				onClick={() => {
					navigate('/plan')
				}}
			>
				<p className='text-center text-[22px]'>
					{userInfo.plan !== UserPlan.NO
						? 'You are in Premium line'
						: 'Skip the line'}
				</p>
			</button>
		</>
	)
}
