import { createPaymentLink } from '~/apiNEW/user/PaymentRequests'
import TextInput from '~/shared/ui/input'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

interface ExtraField {
	id: string
	type: string
	title: string
}

interface PaymentMethod {
	id: string
}

interface ExtraFieldsFormProps {
	extraFields: ExtraField[]
	closeModal: (value?: unknown) => void
	setExtraFieldsModal: React.Dispatch<React.SetStateAction<boolean>>
	selectedMethod?: PaymentMethod
	futurePaymentId: string | undefined
}

export default function ExtraFieldsForm({
	extraFields,
	closeModal,
	setExtraFieldsModal,
	selectedMethod,
	futurePaymentId,
}: ExtraFieldsFormProps) {
	const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>({})
	const [formFilled, setFormFilled] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const handleInputChange = (id: string, value: string) => {
		setFieldValues((prevValues) => ({
			...prevValues,
			[id]: value,
		}))
	}

	const handlePayment = async () => {
		try {
			setIsLoading(true)
			const hasCardDetails = extraFields.some(
				(field) => field.id === 'card_details'
			)
			const hasAddress = extraFields.some((field) => field.id === 'address')

			if (hasCardDetails || hasAddress) {
				const params = new URLSearchParams()
				if (futurePaymentId) params.set('future_payment_id', futurePaymentId)
				if (selectedMethod?.id)
					params.set('payment_method_id', selectedMethod.id)

				Object.entries(fieldValues).forEach(([key, value]) => {
					if (!['cardDetails', 'address'].includes(key)) {
						params.set(key, value)
					}
				})

				if (hasCardDetails) params.append('cardDetails', 'required')
				if (hasAddress) params.append('address', 'required')

				const redirectUrl = `/checkout?${params}`
				window.location.href = redirectUrl
				return
			}

			if (selectedMethod && formFilled && futurePaymentId) {
				const paymentUrl = await createPaymentLink(
					futurePaymentId,
					selectedMethod.id,
					fieldValues
				)
				if (paymentUrl) {
					const a = document.createElement('a')
					a.href = paymentUrl
					a.rel = 'noreferrer'
					a.style.display = 'none'
					document.body.appendChild(a)
					a.click()
					document.body.removeChild(a)
				}
			}
		} catch (error) {
			toast.error('Reload the page and try again.')
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		const allowedTypes = ['card_details', 'address']
		const hasOnlyAllowedFields = extraFields.every((field) =>
			allowedTypes.includes(field.type)
		)
		const hasAllRequiredFields = allowedTypes.every((type) =>
			extraFields.some((field) => field.type === type)
		)

		if (hasOnlyAllowedFields && hasAllRequiredFields) {
			handlePayment()
		}
	}, [extraFields])

	useEffect(() => {
		const fieldsToCheck = extraFields.filter(
			(field) => !['card_details', 'address'].includes(field.id)
		)

		const allFilled = fieldsToCheck.every(
			(field) => fieldValues[field.id] && fieldValues[field.id].trim() !== ''
		)
		setFormFilled(allFilled)
	}, [fieldValues, extraFields])

	return (
		<div className='flex flex-col gap-[24px]'>
			<div className='flex items-start justify-between text-[24px] font-bold'>
				<button onClick={() => setExtraFieldsModal(false)}>
					<img
						src='/icons/chevron.svg'
						alt='chevron'
						className='min-w-[20px] pt-1 opacity-40 transition-all hover:opacity-60'
						width={24}
						height={24}
					/>
				</button>
				Fill in your details
				<button onClick={() => closeModal(undefined)}>
					<img
						src='/icons/close.svg'
						alt='close'
						className='pt-2 opacity-40 transition-all hover:opacity-60'
					/>
				</button>
			</div>
			<form className='flex flex-col gap-[20px] rounded-[12px]'>
				{extraFields.map(
					(field) =>
						!['card_details', 'address'].includes(field.id) && (
							<TextInput
								type={field.type}
								name={field.id}
								placeholder={field.title}
								required
								key={field.id}
								onChange={(e) => handleInputChange(field.id, e.target.value)}
							/>
						)
				)}
			</form>
			<p className='text-center text-[12px] text-white/60'>
				The payments method need more info to proceed with the payment. We do
				not save your data
			</p>
			<button
				onClick={handlePayment}
				className={`flex w-full justify-center rounded-full px-[24px] py-[16px] text-center text-[16px] font-bold transition-all ${
					!formFilled
						? 'bg-[#1d1d1d] opacity-70'
						: 'bg-gradient-to-br from-[#ff8c00] via-[#ff7801] to-[#ff3915] shadow-lg shadow-[#FF5E00]/40'
				}`}
				disabled={!formFilled || isLoading}
			>
				{isLoading ? (
					<img
						src='/icons/spinner.svg'
						alt='spinner'
						className='animate-spin justify-center'
						height='24'
						width='24'
					/>
				) : (
					'Pay'
				)}
			</button>
		</div>
	)
}
