import React from 'react'
import { useEffect, useRef } from 'react'

const PromptLockedModal = ({ navigate, setIsPromptLocked }) => {
	const modalRef = useRef()
	const outsideClick = (e) => {
		if (modalRef.current === e.target) {
			setIsPromptLocked(false)
		}
	}

	useEffect(() => {
		addEventListener('mousedown', outsideClick)
		document.body.style.overflow = 'hidden'

		return () => {
			removeEventListener('mousedown', outsideClick)
			document.body.style.overflow = 'auto'
		}
	}, [])

	return (
		<>
			<div
				className='fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center p-0 transition-all animate-in fade-in-5 slide-in-from-top-8 duration-500 md:p-3'
				ref={modalRef}
			>
				<div className='flex w-full max-w-[500px] flex-col items-center justify-between rounded-[16px] bg-[#171717] px-5 py-12 md:px-12'>
					<p
						className={`flex justify-center text-left text-[26px] font-semibold text-[#FFCB46]`}
					>
						Pro plan
					</p>

					<p className='flex justify-center text-left  text-[22px] font-semibold text-white'>
						is required to unlock this type
					</p>

					<div className='flex w-full justify-center gap-3 pt-6'>
						<button
							onClick={() => setIsPromptLocked(false)}
							className='prompt-modal w-full rounded-[24px] bg-gray py-4 text-[18px] font-semibold text-white hover:bg-[#ff9330] md:text-lg'
						>
							Choose Another
						</button>

						<button
							onClick={() => navigate('/plan')}
							className='prompt-modal w-full rounded-[24px] bg-orange py-4 text-[18px] font-semibold text-white hover:bg-[#ff9330] md:text-lg'
						>
							Upgrade
						</button>
					</div>
				</div>
			</div>
			<div className='duration-400 fixed left-0 top-0 z-10 h-full w-full bg-[#202020]/90 animate-in fade-in-5'></div>
		</>
	)
}

export default PromptLockedModal
