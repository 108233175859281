import { Dispatch, SetStateAction, createContext } from 'react'

type AuthorizationContextType = {
	authorized: boolean
	setAuthorized: Dispatch<SetStateAction<boolean>>
}

const AuthorizationContext = createContext<AuthorizationContextType>({
	authorized: true,
	setAuthorized: () => {},
})

export default AuthorizationContext
