import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

export const autoMask = createSlice({
	name: 'autoMask',
	initialState: {
		value: Cookies.get('automask') || 'manual',
		displayHelp: Cookies.get('automaskHelp') !== 'false',
		isDemo: false,
	},
	reducers: {
		switchAutoMakeMode: (state, action) => {
			Cookies.set('automask', action.payload)
			state.value = action.payload
		},
		setAutoMaskDisplayHelp: (state, action) => {
			Cookies.set('automaskHelp', (!!action.payload).toString())
			state.displayHelp = !!action.payload
		},
		setDemo: (state, action) => {
			state.isDemo = !!action.payload
		},
	},
})

export const { switchAutoMakeMode, setAutoMaskDisplayHelp, setDemo } =
	autoMask.actions

export default autoMask.reducer
