import { ImageQuality, qts } from '~/lib/traits/ImageQuality'

export default function QualitySlider({
	userInfo,
	resolution,
	setResolution,
	navigate,
}) {
	return (
		<div className='flex h-[55px] rounded-full bg-[#171717]'>
			<button
				disabled={resolution === qts[ImageQuality.SD]}
				className='flex w-[30%] items-center justify-center gap-2 rounded-full text-[22px] font-semibold text-[#8b8b8b] transition-all hover:bg-[#393939] disabled:cursor-pointer disabled:bg-orange disabled:text-white'
				onClick={() => {
					setResolution(qts[ImageQuality.SD])
				}}
			>
				{qts[ImageQuality.SD]}
			</button>

			<button
				disabled={resolution === qts[ImageQuality.HD]}
				className='flex w-[30%] items-center justify-center gap-2 rounded-full fill-[#8B8B8B] text-[22px] font-semibold text-[#8b8b8b] transition-all hover:bg-[#393939] disabled:cursor-pointer disabled:bg-orange disabled:fill-white disabled:text-white'
				onClick={() => {
					if (!userInfo.plan.qualites.includes(ImageQuality.HD))
						navigate('/plan')
					else setResolution(qts[ImageQuality.HD])
				}}
			>
				{qts[ImageQuality.HD]}
				{!userInfo.plan.qualites.includes(ImageQuality.HD) && (
					<svg
						width='16'
						height='15'
						viewBox='0 0 16 15'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path d='M8 14.5L0 5.5L2 0.5H14L16 5.5L8 14.5ZM6.20833 4.75H9.79167L8.41667 2H7.58333L6.20833 4.75ZM7.25 11.3958V6.25H2.66667L7.25 11.3958ZM8.75 11.3958L13.3333 6.25H8.75V11.3958ZM11.4583 4.75H14.0833L13 2H10.0833L11.4583 4.75ZM1.91667 4.75H4.54167L5.91667 2H3.02083L1.91667 4.75Z' />
					</svg>
				)}
			</button>

			<button
				disabled={resolution === qts[ImageQuality.UHD]}
				className='flex w-[40%] items-center justify-center gap-2 rounded-full fill-[#8B8B8B] text-[22px] font-semibold text-[#8b8b8b] transition-all hover:bg-[#393939] disabled:cursor-pointer disabled:bg-orange disabled:fill-white disabled:text-white'
				onClick={() => {
					if (!userInfo.plan.qualites.includes(ImageQuality.UHD))
						navigate('/plan')
					else setResolution(qts[ImageQuality.UHD])
				}}
			>
				{qts[ImageQuality.UHD]}
				{!userInfo.plan.qualites.includes(ImageQuality.UHD) && (
					<svg
						width='16'
						height='15'
						viewBox='0 0 16 15'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path d='M8 14.5L0 5.5L2 0.5H14L16 5.5L8 14.5ZM6.20833 4.75H9.79167L8.41667 2H7.58333L6.20833 4.75ZM7.25 11.3958V6.25H2.66667L7.25 11.3958ZM8.75 11.3958L13.3333 6.25H8.75V11.3958ZM11.4583 4.75H14.0833L13 2H10.0833L11.4583 4.75ZM1.91667 4.75H4.54167L5.91667 2H3.02083L1.91667 4.75Z' />
					</svg>
				)}
			</button>
		</div>
	)
}
