import React from 'react'

interface ModalProps {
	width?: string
	setActiveModal: (modal?: any) => void
	children: React.ReactNode
}

export const ModalWindow = ({
	width,
	setActiveModal,
	children,
}: ModalProps) => {
	const handleOutsideClick = (event: React.MouseEvent) => {
		if (!(event.target as Element).closest('.modal-content')) {
			setActiveModal(undefined)
		}
	}

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			setActiveModal(undefined)
		}
	}

	React.useEffect(() => {
		document.addEventListener('keydown', handleKeyDown)

		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [])

	return (
		<div
			onClick={handleOutsideClick}
			className='fixed inset-0 z-[60] flex items-center justify-center bg-black/25 bg-opacity-70 p-[16px] backdrop-blur-xl transition-all animate-in fade-in-5 duration-300'
		>
			<div
				className={`modal-content static max-h-full w-[350px] overflow-auto rounded-[24px] bg-[#161616] p-[24px] shadow-lg animate-in slide-in-from-top-4 duration-500 mmd:p-[32px]`}
				style={{ width: width }}
			>
				{children}
			</div>
		</div>
	)
}
