import {
	activatePromocode,
	checkPromocode,
} from '~/apiNEW/user/UserInfoRequests'
import { OrangeButton } from '~/shared/ui/button/OrangeButton'
import { ModalWindow } from '~/shared/ui/modal'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

interface PromocodeModalProps {
	setActiveModal: (modal?: any) => void
}

type Inputs = {
	code: string
}

export const PromocodeModal = ({ setActiveModal }: PromocodeModalProps) => {
	const [isLoading, setIsLoading] = React.useState(false)

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
	} = useForm<Inputs>()

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		setIsLoading(true)

		try {
			const check = await checkPromocode(data.code)

			if (check.status === 200) {
				const response = await activatePromocode(data.code)
				toast.success(response.data)
			}
			setActiveModal(undefined)
			reset()
		} catch (error: any) {
			// console.log(error)
			if (error.response.status === 404) {
				setError('code', {
					type: 'manual',
					message: 'Promocode not found',
				})
			} else {
				setError('code', {
					type: 'manual',
					message: error.response.data,
				})
			}
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<ModalWindow width='510px' setActiveModal={setActiveModal}>
			<div className='flex items-start justify-between text-[24px] font-bold'>
				Activate Promocode
				<button onClick={() => setActiveModal(undefined)}>
					<img
						src='/icons/close.svg'
						alt='close'
						className='pt-2 opacity-40 transition-all hover:opacity-60'
					/>
				</button>
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				autoComplete='off'
				className='flex flex-col gap-[24px] pt-[24px]'
			>
				<div className='flex flex-col gap-[16px]'>
					<input
						disabled={isLoading}
						placeholder='Promocode'
						{...register('code', {
							required: 'Promocode is required',
						})}
						className={`min-h-[48px] rounded-[12px] border border-[#1c1c1c] bg-[#1c1c1c] px-[16px] outline-none transition-all focus:border-orange ${
							isLoading && 'opacity-80'
						}`}
					/>
					{errors.code && (
						<span className='pl-[16px] text-xs text-red-500'>
							{errors.code.message}
						</span>
					)}
				</div>
				<OrangeButton
					className='flex justify-center'
					disabled={isLoading}
					onClick={() => handleSubmit}
				>
					{isLoading ? (
						<img
							src='/icons/spinner.svg'
							alt='spinner'
							className='animate-spin justify-center'
							height={24}
							width={24}
						/>
					) : (
						'Activate'
					)}
				</OrangeButton>
			</form>
		</ModalWindow>
	)
}
