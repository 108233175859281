import imageCompression from 'browser-image-compression'

export const plans = ['NO', 'BASIC', 'STANDARD', 'PRO']

export const SKIP_CAPTCHA = 'skip-captcha-A0pgQWVW9UI9Ou1Aag0r'
// @ts-ignore

export const getWebsiteNameCapitalized = () => {
	const website = location.host
	return website.charAt(0).toUpperCase() + website.slice(1)
}

export const getWebsiteName = () => {
	return location.host
}

function isMobile() {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	)
}
// @ts-ignore
export const compressImage = async (dataurlOrFile, filename = '') => {
	return new Promise(async (x) => {
		const mobileOptions = {
			maxSizeMB: 0.5,
			maxWidthOrHeight: 1440,
			useWebWorker: true,
			fileType: 'image/jpeg',
			initialQuality: 0.8,
		}
		const desktopOptions = {
			maxWidthOrHeight: 1920,
			useWebWorker: true,
			fileType: 'image/jpeg',
			initialQuality: 0.8,
		}

		const isFile = dataurlOrFile instanceof File

		let file = isFile
			? dataurlOrFile
			: await imageCompression.getFilefromDataUrl(dataurlOrFile, filename)

		if (file.type?.toLowerCase() === 'image/heic') {
			// @ts-ignore
			file = await heic2any({
				blob: file,
				toType: 'image/jpeg',
				quality: 0.8,
			})
		}

		if (file) {
			file = await imageCompression(
				file,
				isMobile() ? mobileOptions : desktopOptions
			)
			const reader = new FileReader()
			reader.onloadend = () => {
				x(reader.result)
			}
			reader.readAsDataURL(file)
		}
	})
}
// @ts-ignore
export const validateDataOnReGenerateImage = (userInfo) => {
	if (
		!sessionStorage.getItem('originalIdStorage') ||
		!sessionStorage.getItem('selectedModeStorage') ||
		sessionStorage.getItem('ageStorage') === null ||
		(sessionStorage.getItem('ageStorage') !== '0' &&
			!userInfo.plan.ageAccess) ||
		!sessionStorage.getItem('bodyTypeStorage') ||
		(sessionStorage.getItem('bodyTypeStorage') !== 'AUTO' &&
			!userInfo.plan.bodyTypeAccess) ||
		!sessionStorage.getItem('resolutionStorage') ||
		(sessionStorage.getItem('resolutionStorage') === 'HD' &&
			!userInfo.plan.qualites.includes(1)) ||
		(sessionStorage.getItem('resolutionStorage') === 'UHD' &&
			!userInfo.plan.qualites.includes(2))
	)
		return false

	return true
}
