const terms = [
	{
		title: 'Company Information',
		text: 'The website undress.love (hereafter referred to as the "Site") is owned and managed by ITAI TECH LTD. This entity, herein referred to as the "Company", "we", "us", or "our", is a company registered in England. Our primary registered office is situated at: Harju maakond, Tallinn, Kesklinna linnaosa, Roseni tn 13, 10111. You can contact us by writing to the above address or by emailing us at support@undress.love',
	},
	{
		title: 'Description of Service',
		text: 'Undress.love is a web-based platform that leverages artificial intelligence to generate adult-themed images. Our service allows users to customize images according to specified parameters such as age, body type, and quality. The responsibility for all generated images rests solely with the user. Additionally, our platform offers an assortment of content by models available for purchase, with transactions securely processed via trusted third-party payment service providers. In line with our commitment to user privacy, we do not store or monitor any user data or generated images.',
	},
	{
		title: 'Limitations of Use',
		text: 'By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:',
		subparagraphs: [
			'1. modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on this website;',
			'2.	remove any copyright or other proprietary notations from any materials and software on this website;',
			'3.	transfer the materials to another person or “mirror” the materials on any other server;',
			'4.	knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts our networks or any other service Undress.love provides;',
			'5.	use this website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;',
			'6.	use this website or its associated services in violation of any applicable laws or regulations;',
			'7.	use this website in conjunction with sending unauthorized advertising or spam;',
			'8.	harvest, collect, or gather user data without the user’s consent; or',
			'9.	use this website or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.',
		],
	},
	{
		title: 'Intellectual Property',
		text: 'The intellectual property in the materials contained in this website are owned by or licensed to Undress.love and are protected by applicable copyright and trademark law. We grant our users permission to download one copy of the materials for personal, non-commercial transitory use.',
		subparagraphs: [
			'This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if you violate any of these restrictions or the Terms of Service, and may be terminated by Undress.love at any time.',
		],
	},
	{
		title: 'User-Generated Content',
		text: 'Undress.love disclaims all liability for the use of content generated by users on our website. Our services allow users to generate images through a free selection process, specifying age, body type, and quality parameters. However, it is imperative to note that we do not store, monitor, or claim responsibility for these user-created images. The user who generates the content maintains absolute ownership and is solely accountable for its use or distribution, along with any potential consequences thereof.',
	},
	{
		title: 'Age Requirements and User Responsibilities',
		text: 'Users must meet a minimum age requirement of 18 years or the age of majority in their jurisdiction, whichever is higher, to access and use our website. By interacting with our services, you are confirming that you meet these age requirements.',
		subparagraphs: [
			'We trust our users to generate images responsibly. You are forbidden from generating images of individuals without their explicit consent or individuals who are minors. Any use of our services to infract upon others’ rights, engage in illegal activities, or carry out prohibited actions will not be tolerated.',
		],
	},
	{
		title: 'Purchases and Payment Options',
		text: 'At Undress.love, we offer distinct packs designed to enhance user experience, with each pack providing specific features and credits. We now offer flexible subscription options including monthly, 3-month, and annual subscriptions, catering to different user needs and preferences. By engaging with our services, users agree to comply with the Terms of Service of Undress.love.',
		subparagraphs: [
			'Our credit system is straightforward: one credit, or coin, allows for generating one photo. This rule is uniformly applied across all plans, ensuring a consistent user experience.',
			'The PRO Pack, available for a one-time fee of $48.99, or as part of a 3-month subscription for $133.99 and an annual subscription for $268.99, grants 600 credits to the user’s balance each month, providing several exclusive privileges:',
			'- Access to various image generation modes, allowing customization of appearance in AI-generated images.',
			'- Removal of branded watermarks from the results of image generation.',
			'- Inclusion of all benefits offered in the STANDARD plan.',
			'At a one-time fee of $23.99, a 3-month subscription for $69.99, or an annual subscription for $133.99, the STANDARD Pack offers 90 credits each month, with users receiving enhanced features including:',
			'- Ultra HD quality for generated images, ensuring superior visual clarity.',
			'- Increased queue space, providing faster processing times compared to the BASIC plan.',
			'- Full access to all the benefits encompassed in the BASIC plan.',
			'For a one-time fee of $10.99, a 3-month subscription for $31.99, or an annual subscription for $63.99, the BASIC Pack allocates 15 credits each month, offering:',
			'- HD quality for image generation, offering clear and detailed results.',
			'Users should familiarize themselves with their chosen pack’s specific features and limitations. All payments are made as a single transaction for one-time fees or as the first payment of a subscription, and users must adhere to applicable laws and respect the rights of others when utilizing our AI-driven image-generation services.',
			// 'Your billing statement will appear as  UG*PixellenceAI372673462.',
		],
	},
	{
		title: 'Liability',
		text: `Our website and the materials on our website are provided on an 'as is' basis. To the extent permitted by law, Undress.love makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, or other violation of rights.`,
		subparagraphs: [
			'In no event shall Undress.love or its suppliers be liable for any consequential loss suffered or incurred by you or any third party arising from the use or inability to use this website or the materials on this website, even if Undress.love or an authorized representative has been notified, orally or in writing, of the possibility of such damage.',
			'In the context of this agreement, “consequential loss” includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under the statute, contract, equity, tort (including negligence), indemnity, or otherwise.',
			'Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.',
		],
	},
	{
		title: 'Zero Tolerance for Explicit Content Involving Minors',
		text: 'We firmly oppose any explicit content involving underage individuals. We proactively monitor for any prohibited content using advanced automated systems to establish a secure environment for all our users. Any accounts found generating or utilizing explicit content of underage individuals will be immediately banned and reported to the relevant authorities in the user’s country.',
	},
	{
		title: 'Accuracy of Materials',
		text: 'The materials appearing on our website are not comprehensive and are for general information purposes only. Undress.love does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on this website, or otherwise relating to such materials or on any resources linked to this website. You should not rely solely on the said materials to make decisions, we advise you to consult other, more complete sources of information. You are solely responsible for strictly relying on our website’s materials. We have the right to change the information on our website without prior notice and users are responsible to monitor said changes. Any historical information present on our website is provided for reference only.',
	},
	{
		title: 'Additional Tools',
		text: `Please note that our website may include third-party tools which are outside of our control. The access to said tools is provided on an 'as is' basis with no warranties, expressed or implied, and without any endorsement. We are not liable for the use of the third-party tools provided on our website. You are solely responsible for any risks associated with using said tools, we recommend reading the relevant third-party provider’s Terms of Service. Undress.love has the right to offer new third-party tools in the future.`,
	},
	{
		title: 'Links',
		text: 'Undress.love has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement, approval, or control by the Undress.love of the site. Use of any such linked site is at your own risk and we strongly advise you make your own investigations with respect to the suitability of those sites.',
	},
	{
		title: 'Indemnification',
		text: 'You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) your Contributions; (2) use of the website; (3) breach of these Terms of Service; (4) any breach of your representations and warranties set forth in these Terms of Service; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the website with whom you connected via the website. ',
		subparagraphs: [
			'Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.',
		],
	},
	{
		title: 'Right to Terminate',
		text: 'We may suspend or terminate your right to use our website and terminate these Terms of Service immediately upon written notice to you for any breach of these Terms of Service.',
	},
	{
		title: 'Severance',
		text: 'Any term of these Terms of Service which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. The validity of the remainder of these Terms of Service is not affected.',
	},
	{
		title: 'Entire Agreement',
		text: 'These Terms of Service and any policies or operating rules posted by us on the website or with respect to the website constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Service shall not operate as a waiver of such right or provision. These Terms of Service operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. ',
		subparagraphs: [
			'If any provision or part of a provision of these Terms of Service is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Service and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment, or agency relationship created between you and us as a result of these Terms of Service or use of the website. You agree that these Terms of Service will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Service and the lack of signing by the parties hereto to execute these Terms of Service.',
		],
	},
	{
		title: 'Governing Law',
		text: 'These Terms of Service are governed by and construed in accordance with the laws of Estonia. You irrevocably submit to the exclusive jurisdiction of the courts in that location.',
	},
	{
		title: 'Chargeback Policy',
		text: 'Last updated September 11, 2023',
		subparagraphs: [
			'In the event you have concerns about a transaction, we strongly recommend contacting our support team before initiating a chargeback. Herein, we lay out our policies concerning chargebacks initiated by our customers.',
			'If you believe an error has occurred in your transaction, we kindly request that you contact us at support@undress.love to discuss any concerns before pursuing a chargeback with your bank or credit card company. This allows us an opportunity to address your concerns directly and provide a resolution that may negate the need for a chargeback.',
			'- Chargeback Process',
			'Should you proceed with a chargeback:',
			'1.	Once a chargeback is received, we will investigate the transaction details.',
			`2.	If the chargeback is deemed valid by our internal review, we will accept it and the funds will be returned according to your bank or credit card company's policy.`,
			'3.	If we contest the chargeback, necessary evidence will be provided to the bank or credit card company to validate the original transaction.',
			'Please understand that the chargeback process might differ based on your bank or credit card company. We will do our utmost to expedite the process, but resolution times are often determined by the financial institution.',
			'- Payment Method Alterations',
			'If, for any reason, Undress.love is unable to resolve a chargeback dispute through your initial payment method, we will seek an alternate method to settle the matter. Some payment methods in specific jurisdictions might not support the chargeback process to the original method used.',
			'- Refund Policy',
			'We understand that occasionally users may wish to reconsider their purchases. At Undress.love, if you find yourself unsatisfied with a purchase, you may be eligible for a refund. Refund requests must be made within a period of 14 days from the purchase date. Once approved, the processing of the refund might take up to 10 business days, influenced by bank conditions. Refunds are typically issued via the initial payment method. However, in situations where refunding to the original method is not possible, we will seek an alternative means to return the funds. For all refund-related inquiries or to initiate a request, please reach out to our dedicated support team.',
			'- How to Address Concerns',
			'Before pursuing a chargeback or requesting a refund, please contact us at support@undress.love to address any grievances.',
			'- No Disparagement',
			'You agree not to disparage us in any capacity. Disparagement, for clarity, means making derogatory, false, or negative statements about Undress.love’s products, services, members, managers, or employees.',
			'- Release of Liability',
			'Upon using our services, you release Undress.love, its representatives, employees, managers, and affiliates from any claims, demands, liabilities, or costs arising from disputes involving chargebacks.',
			'- No Admission of Liability',
			'Initiating a chargeback or our decision to not contest it does not imply an admission of fault by Undress.love. This Chargeback Policy should not be interpreted in other contexts as an admission or concession of liability.',
			'- Questions',
			'For queries regarding our Chargeback Policy, kindly reach out to us at support@undress.love.',
			'- Revision Date',
			'This Chargeback Policy was last updated on 11 September 2023 (“Revision Date”). If you were a user before the Revision Date, this supersedes any prior chargeback policies.',
		],
	},
]

export default terms
