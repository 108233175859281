import { ImageQuality } from './ImageQuality'

export class UserPlan {
	private static AllValues: { [name: string]: UserPlan } = {}

	static readonly NO = new UserPlan('no', [ImageQuality.SD], false, false)
	static readonly BASIC = new UserPlan(
		'basic',
		[ImageQuality.SD, ImageQuality.HD],
		false,
		true
	)
	static readonly STANDARD = new UserPlan(
		'standard',
		[ImageQuality.SD, ImageQuality.HD],
		false,
		true
	)
	static readonly PRO = new UserPlan(
		'pro',
		[ImageQuality.SD, ImageQuality.HD, ImageQuality.UHD],
		true,
		true
	)

	private constructor(
		public readonly displayName: string,
		public readonly qualites: ImageQuality[],
		public readonly ageAccess: boolean,
		public readonly bodyTypeAccess: boolean
	) {
		UserPlan.AllValues[displayName] = this
	}

	public static get(name: string): UserPlan {
		return UserPlan.AllValues[name]
	}
}
