import React from 'react'

interface ButtonProps {
	onClick: () => void
	children: React.ReactNode
	id?: string
	className?: string
	disabled?: boolean
}

export const OrangeButton = ({
	onClick,
	children,
	id,
	className,
	disabled = false,
	...props
}: ButtonProps) => {
	return (
		<button
			id={id}
			onClick={onClick}
			disabled={disabled}
			className={`w-full rounded-full py-[16px] text-center text-[16px] font-bold ${className} ${
				disabled ? 'bg-gray opacity-60' : 'bg-orange'
			}`}
			{...props}
		>
			{children}
		</button>
	)
}
