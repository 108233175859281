import { API_HOST } from '~/shared/api'
import axios from 'axios'
import Cookies from 'js-cookie'

export async function sendUserInfoRequest() {
	if (!Cookies.get('token')) return
	return axios.get(`${API_HOST}/user/info`, {
		headers: {
			Authorization: `Bearer ${Cookies.get('token')}`,
		},
	})
}

export async function getPlanInfo() {
	return axios.get(`${API_HOST}/sub/info`, {
		headers: {
			Authorization: `Bearer ${Cookies.get('token')}`,
		},
	})
}

export async function cancelPlan() {
	return axios.post(`${API_HOST}/sub/cancel`, null, {
		headers: {
			Authorization: `Bearer ${Cookies.get('token')}`,
		},
	})
}

export async function unsubUserPlan(planId, paySys) {
	return axios.post(
		`${API_HOST}/user/sub/cancel?type=${paySys}&id=${planId}`,
		{},
		{
			headers: {
				Authorization: `Bearer ${Cookies.get('token')}`,
			},
		}
	)
}

export async function requestNewGeneration() {
	return axios.post(
		`${API_HOST}/task/markCurrentAsViewed`,
		{},
		{
			headers: {
				Authorization: `Bearer ${Cookies.get('token')}`,
			},
		}
	)
}

export async function getPlanPrices() {
	return axios.get(`${API_HOST}/payments/prices`, {
		headers: {
			Authorization: `Bearer ${Cookies.get('token')}`,
		},
	})
}

export async function getPaymentMethods(selectedPlan) {
	return axios.get(`${API_HOST}/payments/methods?productId=${selectedPlan}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get('token')}`,
		},
	})
}
export async function getPaymentMethodsByGroup(groupID) {
	return axios.get(`${API_HOST}/payments/methods?productGroupId=${groupID}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get('token')}`,
		},
	})
}
export async function getPaymentUri(
	future_payment_id,
	payment_method_id,
	extra_fields
) {
	return axios.post(
		`${API_HOST}/payments/create`,
		{
			future_payment_id: future_payment_id,
			payment_method_id: payment_method_id,
			extra_fields: extra_fields,
		},
		{
			headers: {
				Authorization: `Bearer ${Cookies.get('token')}`,
			},
		}
	)
}
