import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { Provider } from 'react-redux'
import {
	Navigate,
	Route,
	BrowserRouter as Router,
	Routes,
	useParams,
	useSearchParams,
} from 'react-router-dom'
import Cookies from 'js-cookie'
import ContactUs from './components/contactUs'
import Edit from './components/edit/edit'
import { General } from './components/general'
import { Plan } from './components/plan/plan'
import Privacy from './components/privacy'
import Refunds from './components/refunds'
import Rules from './components/rules'
import TermsOfService from './components/terms'
import './i18n/i18n'
import AuthorizationContext from './lib/authorizationContext'
import { store } from './redux/store'

export default function App() {
	const [authorized, setAuthorized] = useState<boolean>(true)

	return (
		<Provider store={store}>
			<AuthorizationContext.Provider value={{ authorized, setAuthorized }}>
				<RouteSelect />
			</AuthorizationContext.Provider>
		</Provider>
	)
}

const RouteSelect = () => {
	const ReferralActivation = () => {
		let { code } = useParams()
		if (code) {
			Cookies.set('referral', code)
		}
		const params = new URLSearchParams(window.location.search)

		const subId = params.get('subid')

		if (subId) {
			Cookies.set('subId', subId)
		}

		return <Navigate to='/' />
	}

	const LoginAsUser = () => {
		const url = new URL(window.location.href)

		const token = url.searchParams.get('token')
		const provider = url.searchParams.get('provider')
		const isNewUser = url.searchParams.get('new_user')

		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: 'aevent',
			event_name: `${isNewUser ? 'sign_up' : 'sign_in'}`,
			provider: provider,
		})

		if (window.umami) {
			window.umami.track(`${isNewUser ? 'Sign Up' : 'Sign In'}`, {
				provider: provider,
			})
		}

		if (token) {
			Cookies.set('token', token, { expires: 60 })
		}

		location.replace('/edit')

		return <>Loading..</>
	}

	return (
		<Router>
			<Toaster
				toastOptions={{
					style: {
						padding: '16px',
						background: '#1d1d1d',
						color: '#ffffff',
						borderRadius: '12px',
					},
					iconTheme: {
						primary: '#ff7a00',
						secondary: '#1d1d1d',
					},
					position: 'top-center',
					duration: 3000,
				}}
			/>
			<Routes>
				<Route path='/' element={<General />} />
				<Route path='*' element={<Navigate to='/' replace />} />
				<Route path='/rules' element={<Rules />} />
				<Route path='/edit' element={<Edit />} />
				<Route path='/plan' element={<Plan />} />
				<Route path='/privacy' element={<Privacy />} />
				<Route path='/terms' element={<TermsOfService />} />
				<Route path='/refunds' element={<Refunds />} />
				<Route path='/contact' element={<ContactUs />} />
				<Route path='/login' element={<LoginAsUser />} />
				<Route path='/ref/:code' element={<ReferralActivation />} />
			</Routes>
		</Router>
	)
}
