import axios from 'axios'
import Cookies from 'js-cookie'

export const API_HOST = 'https://api-v2.undress.love'
export const DISCORD_API_HOST = 'https://love.bakeryschool.cc'
export const GOOGLE_API_HOST = 'https://api.animestyling.cfd'

export const api = axios.create({
	baseURL: API_HOST,
})

api.interceptors.request.use((config) => {
	const accessToken = Cookies.get('token')

	// Temporary fix to remove 'bearer' from token in cookies
	if (accessToken?.toLowerCase().includes('bearer')) {
		Cookies.set('token', accessToken.replace(/bearer\s/gi, ''))
	}

	if (accessToken) {
		config.headers.Authorization = `Bearer ${accessToken}`
	}

	return config
})

api.interceptors.response.use(
	(config) => config,
	(error) => {
		if (error.response.status === 401) {
			Cookies.remove('token')
		}

		throw error
	}
)
