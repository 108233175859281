import { getWebsiteNameCapitalized } from '~/lib/utils'
import { Footer } from '~/shared/ui/footer'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Link } from 'react-router-dom'

export default function ContactUs() {
	return (
		<HelmetProvider>
			<div className='flex min-h-screen flex-col bg-black'>
				<Helmet>
					<title>Contact Us</title>
				</Helmet>
				<div className='flex flex-grow flex-col items-center gap-[20px]'>
					<div className='flex w-full items-center justify-center gap-[40px] p-[16px] pt-[50px] mmd:p-[24px] llg:pt-[100px]'>
						<div className='flex w-full max-w-[1110px] flex-col items-center justify-between gap-[40px]'>
							<div className='flex w-full items-center justify-between'>
								<Link to='/edit'>
									<h4 className='text-[28px] font-medium'>
										{getWebsiteNameCapitalized()}
									</h4>
								</Link>
								<Link to='/edit'>
									<img src='logo.svg' className='h-auto w-[45px]' alt='logo' />
								</Link>
							</div>
							<div className='flex w-full flex-col justify-start gap-8'>
								<h5 className='text-[28px] font-semibold'>Contact Us</h5>
								<div className='flex flex-col gap-4'>
									<h6 className='text-[24px] font-semibold'>
										If you want to get in touch with us because you've
										encountered a problem on the website, then please use:
									</h6>
									<div className='flex flex-col gap-[8px]'>
										<a href='mailto:support@undress.love' className='text-lg'>
											Email: support@undress.love
										</a>
										<a href='tel:+447418703508' className='text-lg'>
											Phone number: +44 7418 703508
										</a>
									</div>
									{/* <p className='text-xs opacity-40'>
										Your billing statement will appear as
										UG*PixellenceAI372673462.
									</p> */}
									<p className='text-xs opacity-40'>
										ITAI TECH LTD (ITAI TECH LTD, 20 Wenlock Road, London,
										England, N1 7GU)
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='p-[16px] mmd:p-[24px]'>
					<div className='flex justify-center'>
						<Link
							to='/edit'
							onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
							className='p-4 text-[22px] font-medium text-white/50 transition-all hover:text-white/30'
						>
							Back to edit
						</Link>
					</div>
					<Footer />
				</div>
			</div>
		</HelmetProvider>
	)
}
