interface CheckboxProps {
	id?: string
	isChecked: boolean
	onClick: () => void
}

export const Checkbox = ({ id, isChecked, onClick }: CheckboxProps) => {
	return (
		<button id={id} onClick={onClick}>
			<div
				className={`flex h-[24px] w-[24px] items-center justify-center rounded-[6px] ${
					isChecked
						? 'bg-orange'
						: 'border-2 border-white/[24%] transition-all hover:border-white/[36%]'
				}`}
			>
				{isChecked && <img src='/icons/check.svg' alt='check' />}
			</div>
		</button>
	)
}
