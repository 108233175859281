// EMAIL
export const USER_PATTERN = new RegExp(
	'^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*"$'
)

export const DOMAIN_PATTERN = new RegExp(
	'^(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$'
)

export const IP_PATTERN = new RegExp(
	'^\\[(?:(25[0-5]|[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\\.){3}(25[0-5]|[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\\]$'
)

// PASSWORD
export const PASSWORD_REGEX = new RegExp(
	'^[a-zA-Zа-яА-Я\\d#$@!%&*?+=/()\\[\\]\\\\.\'":;<>|{}\\-,^~`]{8,128}$'
)
