import { switchAutoMakeMode } from '~/redux/autoMaskReducer'
import React from 'react'
import { useDispatch } from 'react-redux'

const PromptButton = ({
	setIsPromptLocked,
	onClick,
	userPlan,
	navigate,
	selectedMode,
	isAgeVerified,
	promptName,
	promptLabel = promptName[0].toUpperCase() + promptName.slice(1).toLowerCase(),
	setSelectedMode,
	setShowModePicker,
}: any) => {
	const dispatch = useDispatch()

	return (
		<button
			disabled={false}
			className={`${selectedMode == promptName.toUpperCase()} 
        ${
					selectedMode == promptName.toUpperCase()
						? 'bg-[#EB7100] text-[white]'
						: 'bg-[#161616] hover:scale-[98%] hover:bg-[#393939] disabled:bg-opacity-50 disabled:text-[#9c9c9c] disabled:hover:scale-100 disabled:hover:bg-[#161616] disabled:hover:bg-opacity-50'
				}
        ${
					['LINGERIE', 'BIKINI'].includes(promptName) || userPlan == 'pro'
						? ''
						: ['UNDRESS', 'SHIBARI', 'BDSM', 'SEX'].includes(promptName)
						? 'border-2 border-dashed border-[#9747FF] bg-[#9747FF]/20 hover:bg-[#4C2480]/60'
						: 'border-2 border-dashed bg-[#343434] hover:bg-[#4a4a4a]'
				}
        relative w-full rounded-full px-6 py-4 font-semibold transition-all`}
			onClick={() => {
				dispatch(switchAutoMakeMode('auto'))
				if (selectedMode !== promptName.toUpperCase()) {
					if (
						promptName === 'BIKINI' ||
						promptName === 'LINGERIE' ||
						userPlan == 'pro'
					) {
						setShowModePicker(false)
						setSelectedMode(promptName.toUpperCase())
					} else {
						onClick(promptName.toUpperCase())
						setIsPromptLocked(true)
					}
				}
			}}
		>
			<div
				className={`flex justify-center gap-2 ${
					promptName.toUpperCase() === 'UNDRESS' && selectedMode == 'UNDRESS'
						? 'text-[white]'
						: ''
				}`}
			>
				{promptLabel}
			</div>
		</button>
	)
}

export default PromptButton
