import React, { useState } from 'react'

interface TextInputProps {
	type?: string
	name: string
	placeholder?: string
	required?: boolean
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const TextInput = ({
	type = 'text',
	name,
	placeholder,
	required = false,
	onChange,
}: TextInputProps) => {
	const [value, setValue] = useState('')

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value)
		if (onChange) {
			onChange(event)
		}
	}

	return (
		<label>
			<input
				type={type}
				name={name}
				value={value}
				placeholder={placeholder}
				required={required}
				onChange={handleChange}
				className='w-full appearance-none rounded-[12px] bg-[#222222] px-4 py-[10px] leading-7 text-white/60'
			/>
		</label>
	)
}

export default TextInput
